
import { defineComponent, PropType } from "vue";
import { eAlertType } from "./cmpAlertLib";

/**
 * BaseAlert defines the common approach used for Alert Message display. It is intended to be wrapped, leaving styling and layout placement to parent components.
 */
export default defineComponent({
  name: "BaseAlert",
  props: {
    alertMessage: {
      type: String,
      default: null,
    },
    alertType: {
      type: Number as PropType<eAlertType>,
      default: eAlertType.Success,
    },
  },
  data() {
    return {
      alertTypes: eAlertType,
    };
  },
});


import { eAppAccessType, ICustomerData, INavLink } from "../lib/shared-definitions";
import { defineComponent, PropType } from "vue";
import { useDisplay } from "vuetify";

export default defineComponent({
  name: "BottomNav",
  setup() {
    const { mobile } = useDisplay();
    return { mobile };
  },
  props: {
    userData: {
      type: Object as PropType<ICustomerData>,
    },
    links: {
      type: Array as PropType<Array<INavLink>>,
      default: () => {
        return [{ icon: "mdi-delete", name: "Todo", path: "/todo", accessTypes: [eAppAccessType.AUTHENTICATED] }];
      },
    },
  },
  data() {
    return {};
  },
  methods: {
    onClick(links: Array<INavLink>) {
      links.forEach((item) => {
        item.isActive = false;
      });
    },
  },
});

import { ElectronicSignatureViewModel } from "@/lib/models/WorkflowViewModels";

import { getSampleElectronicSignatureData } from "./WorkFlowService";
import { GenericActionFailedErrorMessage } from "@/lib/shared-definitions";

//new functions
export function getElectronicSignatureInfo(id: string, companyId: string): Promise<ElectronicSignatureViewModel> {
  return new Promise((resolve, rejected) => {
    setTimeout(() => {
      const sampleElectronicSignatureData = getSampleElectronicSignatureData();
      if (sampleElectronicSignatureData != null) {
        resolve(sampleElectronicSignatureData);
      } else {
        rejected(new Error("Unable to fetch ElectronicSignatureInfo data"));
      }
    }, 1000);
  })
    .then((result) => {
      return result as ElectronicSignatureViewModel;
    })
    .catch((error) => {
      console.error(error);
      throw { error: error, message: GenericActionFailedErrorMessage };
    });

  //todo:workflow-api axios.get(`/api/ElectronicSignature/electronicSignatureStatus/${encodeURIComponent(id)}?cid=${encodeURIComponent(cid)}`, null)
}
//end new functions

export function signatureProcess(id: string, cid: string) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve("/#/docuSign");
    }, 1000);
  }).catch((error) => {
    console.error(error);
    throw { error: error, message: GenericActionFailedErrorMessage };
  });

  //todo:workflow-api axios.get(`/api/ElectronicSignature/signatureProcess/${encodeURIComponent(id)}?cid=${encodeURIComponent(cid)}`, null)
}

export function getEnvelopeRecipientView(id: string, cid: string) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve("/#/docuSign");
    }, 1000);
  }).catch((error) => {
    console.error(error);
    throw { error: error, message: GenericActionFailedErrorMessage };
  });
  //todo:workflow-api  axios.get(`/api/ElectronicSignature/envelopeRecipientView/${encodeURIComponent(id)}?cid=${encodeURIComponent(cid)}`, null)
}

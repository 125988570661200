
import { defineComponent, PropType, ref } from "vue";
import { useDisplay } from "vuetify";
import { eIconPositionMixin, IPropertyData } from "../lib/shared-definitions";
import { useIsAuthenticated } from "@/composition-api/useIsAuthenticated";
import * as Utils from "@/lib/utils";

export default defineComponent({
  name: "PropertyMenu",
  mixins: [eIconPositionMixin],
  props: {
    properties: {
      type: Array as PropType<Array<IPropertyData>>,
      default: () => {
        return new Array<IPropertyData>();
      },
    },
    modelValue: {
      type: Object as PropType<IPropertyData>,
      default: () => {
        return {} as IPropertyData;
      },
    },
  },
  setup() {
    const { mobile } = useDisplay();
    const isAuthenticated = useIsAuthenticated();
    const isOpen = ref(false);
    return { mobile, isAuthenticated, isOpen };
  },
  emits: ["update:modelValue"],
  computed: {
    propertySelected: {
      get() {
        return this.modelValue;
      },
      set(value: IPropertyData) {
        this.$emit("update:modelValue", value);
      },
    },
  },
  watch: {},
  data: () => ({
    isLoading: false,
  }),
  methods: {
    selectProperty(value: IPropertyData) {
      this.isOpen = false;
      if (value) {
        this.propertySelected = value;
      }
    },
    propertyName(property: IPropertyData): string {
      return Utils.toFormattedAddress(property) + (property.isJob ? " - Job" : "");
    },
  },
});

import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import { registerAccessTypeGuard } from "./guard";

export enum ePaymentPageAction {
  payment,
  saveCard,
  saveCheck,
}

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    redirect: { name: "loading" },
    meta: {
      requiresAuth: true,
    },
  },

  //TODO: pending review/removal. Might want to use this as a default page when first loading the app.
  //specifically this would avoid seeing "not-authorized" the browser address bar on initial loading of app with no/invalid b2c auth.
  {
    path: "/loading",
    name: "loading",
    component: () => import(/* webpackChunkName: "common" */ "../views/LoadingView.vue"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/snapshot",
    name: "snapshot",
    component: () => import(/* webpackChunk: "snapshot" */ "../views/SnapshotView.vue"),
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/invoices",
    name: "invoices",
    props: (route) => ({ paymentDue: route.query.paymentDue, dateFrom: route.query.dateFrom }),
    component: () => import(/* webpackChunkName: "invoices" */ "../views/InvoicesView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/pay/:type/:id/:step?",
    alias: "/review/:type/:id/:step?",
    name: "pay",
    props: true,
    component: () => import(/* webpackChunkName: "workflow" */ "../views/WorkFlowView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/quotes",
    name: "quotes",
    component: () => import(/* webpackChunkName: "quotes" */ "../views/QuotesView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/invoice/pdf/:id/cid/:cid",
    alias: "/quote/pdf/:id/cid/:cid",
    name: "viewPdf",
    props: true,
    component: () => import(/* webpackChunkName: "viewPdf" */ "../views/ViewPdfView.vue"),
    meta: {
      hideNavbar: true,
    },
  },

  {
    path: "/payment-methods",
    name: "payment-methods",
    component: () => import(/* webpackChunkName: "payment-methods" */ "../views/SavedPaymentMethodsView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/appointments",
    name: "appointments",
    component: () => import(/* webpackChunkName: "appointments" */ "../views/AppointmentView.vue"),
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/:pathMatch(.*)*",
    name: "not-found",
    component: () => import(/* webpackChunkName: "common" */ "../views/NotFound.vue"),
  },
  {
    path: "/not-authorized",
    name: "not-authorized",
    component: () => import(/* webpackChunkName: "common" */ "../views/NotAuthorized.vue"),
  },
  {
    path: "/change-company",
    name: "companies",
    component: () => import(/* webpackChunkName: "appointments" */ "../views/ChangeCompanyView.vue"),
    meta: {
      hideNavbar: true,
      requiresAuth: true,
    },
  },
  {
    path: "/pay/confirmation",
    name: "confirmation",
    component: () => import(/* webpackChunkName: "Confirmation" */ "../views/ConfirmationView.vue"),
    meta: {
      hideNavbar: true,
    },
  },
  /**
   * Payment pages - make payment, save card, save check - all same page, but different props
   */
  {
    path: "/payment",
    name: "payment",
    component: () => import(/* webpackChunkName: "payment" */ "../views/PaymentView.vue"),
    props: {
      pageAction: ePaymentPageAction.payment,
      redirectUrl: "/snapshot",
      successRedirectUrl: "/snapshot",
      submitButtonLabel: "Pay Now",
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/save-card",
    name: "save-card",
    component: () => import(/* webpackChunkName: "payment" */ "../views/PaymentView.vue"),
    props: {
      pageAction: ePaymentPageAction.saveCard,
      redirectUrl: "/payment-methods",
      successRedirectUrl: "/payment-methods",
      submitButtonLabel: "Save Card",
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/save-check",
    name: "save-check",
    component: () => import(/* webpackChunkName: "payment" */ "../views/PaymentView.vue"),
    props: {
      pageAction: ePaymentPageAction.saveCheck,
      redirectUrl: "/payment-methods",
      successRedirectUrl: "/payment-methods",
      submitButtonLabel: "Save",
    },
    meta: {
      requiresAuth: true,
    },
  },
  //////////////////////////////////////
  //experimental routes, pending review
  {
    path: "/signout",
    name: "signout",
    component: () => import(/* webpackChunkName: "common" */ "../views/SignoutView.vue"),
    meta: {
      requiresAuth: false,
    },
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

registerAccessTypeGuard(router);

//either use msal guards approach an useMsal or useMsalAuthentication.
//todo: if we switch to nav msal guard auth approach, we'll use this
//pending review/removal
//registerGuard(router);

export default router;

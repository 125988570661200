import { IWorkflowItem, IWorkflowViewData } from "@/lib/shared-definitions";
import { store } from "../store";
import {
  ElectronicSignatureViewModel,
  ConsumerPortalLinkInfoViewModel,
  eWorkflowType,
} from "@/lib/models/WorkflowViewModels";
import { PaymentGatewayCapabilitiesModel } from "fieldedge-typescript-models-core/models/consumerManagementPortal/PaymentGatewayCapabilitiesModel";

//Begin: temporary functions

export function clearAllData() {
  localStorage.removeItem("workflowViewData");
  store.dispatch("workflowState/resetState");
}

export function getSampleElectronicSignatureData(): ElectronicSignatureViewModel {
  const sampleData = localStorage.getItem("sampleElectronicSignature");
  return sampleData != null ? JSON.parse(sampleData) : ({} as ElectronicSignatureViewModel);
}

export function getViewData(): IWorkflowViewData {
  const viewData = localStorage.getItem("workflowViewData");
  return viewData != null ? JSON.parse(viewData) : ({} as IWorkflowViewData);
}

export function setViewData(viewData: IWorkflowViewData) {
  localStorage.setItem("workflowViewData", JSON.stringify(viewData));
}
//End: temporary functions

// New functions
export function getConsumerPortalLinkInfoData(): ConsumerPortalLinkInfoViewModel {
  const consumerPortalLinkInfo = localStorage.getItem("consumerPortalLinkInfo");
  return consumerPortalLinkInfo != null ? JSON.parse(consumerPortalLinkInfo) : ({} as ConsumerPortalLinkInfoViewModel);
}

export function getWorkFlowSteps(
  workflowType: eWorkflowType,
  isElectronicSignature: boolean,
  disableSignPage: boolean
): Array<IWorkflowItem> {
  switch (workflowType) {
    case eWorkflowType.InvoiceOnly:
      return getInvoiceOnlyWorkflow();
    case eWorkflowType.QuoteOnly:
      return getQuoteWorkflow(isElectronicSignature, disableSignPage);
    default:
      return getInvoiceQuoteWorkflow(isElectronicSignature, disableSignPage);
  }
}

function getInvoiceOnlyWorkflow(): Array<IWorkflowItem> {
  return [
    {
      name: "Review",
      component: "ReviewView",
      isDisabled: false,
    },
    {
      name: "Pay Options",
      component: "PayOptionView",
      isDisabled: false,
    },
    {
      name: "Checkout",
      component: "CheckoutView",
      isDisabled: false,
    },
  ];
}

function getInvoiceQuoteWorkflow(isElectronicSignature: boolean, disableSignPage: boolean): Array<IWorkflowItem> {
  if (isElectronicSignature) {
    return [
      {
        name: "Review",
        component: "ReviewView",
        isDisabled: false,
      },
      {
        name: "Quote",
        component: "QuoteOptionView",
        isDisabled: false,
      },
      {
        name: "Sign",
        component: "SignatureView",
        isDisabled: disableSignPage,
      },
      {
        name: "Pay Options",
        component: "PayOptionView",
        isDisabled: false,
      },
      {
        name: "Checkout",
        component: "CheckoutView",
        isDisabled: false,
      },
    ];
  } else {
    return [
      {
        name: "Review",
        component: "ReviewView",
        isDisabled: false,
      },
      {
        name: "Quote",
        component: "QuoteOptionView",
        isDisabled: false,
      },
      {
        name: "Pay Options",
        component: "PayOptionView",
        isDisabled: false,
      },
      {
        name: "Checkout",
        component: "CheckoutView",
        isDisabled: false,
      },
    ];
  }
}

function getQuoteWorkflow(isElectronicSignature: boolean, disableSignPage: boolean): Array<IWorkflowItem> {
  if (isElectronicSignature) {
    return [
      {
        name: "Review",
        component: "ReviewView",
        isDisabled: false,
      },
      {
        name: "Accept",
        component: "QuoteOptionView",
        isDisabled: false,
      },
      {
        name: "Sign",
        component: "SignatureView",
        isDisabled: disableSignPage,
      },
      {
        name: "Pay Options",
        component: "PayOptionView",
        isDisabled: false,
      },
      {
        name: "Checkout",
        component: "CheckoutView",
        isDisabled: false,
      },
    ];
  } else {
    return [
      {
        name: "Review",
        component: "ReviewView",
        isDisabled: false,
      },
      {
        name: "Accept",
        component: "QuoteOptionView",
        isDisabled: false,
      },
      {
        name: "Pay Options",
        component: "PayOptionView",
        isDisabled: false,
      },
      {
        name: "Checkout",
        component: "CheckoutView",
        isDisabled: false,
      },
    ];
  }
}

//End: new functions

import { BalanceAmounts, WorkOrderInfoViewModel } from "@/lib/models/WorkflowViewModels";
import { GenericActionFailedErrorMessage } from "@/lib/shared-definitions";
import { AxiosError } from "axios";
import { createAxiosCMP, createAxiosCMPPascalCase } from "@/lib/axios/cmpbackend";
import { WorkOrderModel } from "fieldedge-typescript-models-core";

//new functions
export async function getWorkOrderInfoAsync(workOrderId: string, companyId: string): Promise<WorkOrderInfoViewModel> {
  const ax = createAxiosCMPPascalCase();
  return await ax
    .get(`/companies/${companyId}/work-orders/${workOrderId}/work-orders-info`)
    .then((response) => {
      const { data } = response;
      return data;
    })
    .catch((error) => {
      if (error instanceof AxiosError) {
        console.error(error.message);
      } else console.error(error);
      throw { error: error, message: GenericActionFailedErrorMessage };
    });
}

/**
 * Creates FE Web Timeline Note and Alert
 * @param companyId
 * @param workOrderId
 * @returns
 */
export async function quoteSkipped(companyId: string, workOrderId: string): Promise<string> {
  const ax = createAxiosCMP();
  return await ax
    .post(`/companies/${companyId}/work-orders/${workOrderId}/quote-skipped`)
    .then((response) => {
      return response.statusText;
    })
    .catch((error) => {
      if (error instanceof AxiosError) {
        console.error(error.message);
      } else console.error(error);
      throw { error: error, message: GenericActionFailedErrorMessage };
    });
}

export async function getBalanceAmounts(workOrder: WorkOrderModel, depositPercent: number): Promise<BalanceAmounts> {
  const ax = createAxiosCMPPascalCase();
  return await ax
    .post(`deposit-percent/${depositPercent}/balance-amounts`, workOrder)
    .then((response) => {
      const { data } = response;
      return data;
    })
    .catch((error) => {
      if (error instanceof AxiosError) {
        console.error(error.message);
      } else console.error(error);
      throw { error: error, message: GenericActionFailedErrorMessage };
    });
}

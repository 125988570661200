import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderSlot as _renderSlot, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!
  const _component_v_overlay = _resolveComponent("v-overlay")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_menu = _resolveComponent("v-menu")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_v_overlay, {
      modelValue: _ctx.isLoading,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isLoading) = $event)),
      scrim: false,
      transition: "none",
      persistent: true,
      class: "align-center justify-center loader"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_progress_circular, {
          indeterminate: "",
          color: "green",
          size: 65,
          width: 7
        })
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_v_menu, {
      modelValue: _ctx.isOpen,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.isOpen) = $event)),
      "close-on-content-click": false,
      location: "end"
    }, {
      activator: _withCtx(({ props }) => [
        _renderSlot(_ctx.$slots, "activator", { props: props })
      ]),
      default: _withCtx(() => [
        _createVNode(_component_v_list, { class: "menu-margin" }, {
          default: _withCtx(() => [
            (_ctx.isAuthenticated)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createVNode(_component_v_list_item, { class: "menu-item-style menu-item-title" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_list_item_title, null, {
                        default: _withCtx(() => [
                          _createTextVNode("My Account")
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_divider),
                  _createVNode(_component_v_list_item, {
                    class: "menu-item-style",
                    link: ""
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_list_item_title, { onClick: _ctx.changeEmail }, {
                        default: _withCtx(() => [
                          _createTextVNode("Edit Username")
                        ]),
                        _: 1
                      }, 8, ["onClick"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_list_item, {
                    link: "",
                    class: "menu-item-style"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_list_item_title, { onClick: _ctx.changePassword }, {
                        default: _withCtx(() => [
                          _createTextVNode("Edit Password")
                        ]),
                        _: 1
                      }, 8, ["onClick"])
                    ]),
                    _: 1
                  }),
                  (_ctx.hasMultiCompanies)
                    ? (_openBlock(), _createBlock(_component_v_list_item, {
                        key: 0,
                        class: "menu-item-style",
                        link: ""
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_list_item_title, { onClick: _ctx.switchCompany }, {
                            default: _withCtx(() => [
                              _createTextVNode("Switch Customer Account")
                            ]),
                            _: 1
                          }, 8, ["onClick"])
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  _createVNode(_component_v_list_item, {
                    class: "menu-item-style",
                    link: ""
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_list_item_title, { onClick: _ctx.openPaymentMethods }, {
                        default: _withCtx(() => [
                          _createTextVNode("Saved Payment Methods")
                        ]),
                        _: 1
                      }, 8, ["onClick"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_list_item, {
                    class: "menu-item-style",
                    link: ""
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_list_item_title, { onClick: _ctx.signOut }, {
                        default: _withCtx(() => [
                          _createTextVNode("Sign Out")
                        ]),
                        _: 1
                      }, 8, ["onClick"])
                    ]),
                    _: 1
                  })
                ], 64))
              : (_openBlock(), _createBlock(_component_v_list_item, {
                  key: 1,
                  class: "menu-item-style",
                  link: "",
                  style: {"width":"212px"}
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_list_item_title, { onClick: _ctx.signIn }, {
                      default: _withCtx(() => [
                        _createTextVNode("Sign In")
                      ]),
                      _: 1
                    }, 8, ["onClick"])
                  ]),
                  _: 1
                }))
          ]),
          _: 1
        })
      ]),
      _: 3
    }, 8, ["modelValue"])
  ], 64))
}

import { defineComponent, PropType, ref, useAttrs } from "vue";
import { useDisplay } from "vuetify";
import { eIconPositionMixin, INavSection } from "@/lib/shared-definitions";
import { useMsal } from "@/composition-api/useMsal";
import { useIsAuthenticated } from "@/composition-api/useIsAuthenticated";
import { changeEmailRequest, loginChangePasswordRequest, loginRequest } from "@/settings/authConfig";
import { eActions, useStore } from "@/store";
import AccountService from "@/services/AccountService";

export default defineComponent({
  name: "MyAccountMenu",
  mixins: [eIconPositionMixin],
  props: {
    myAccountMenuData: {
      type: Object as PropType<INavSection>,
      default: () => {
        return {};
      },
    },
    value: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isLoading: false,
  }),
  setup(props) {
    const { mobile } = useDisplay();
    const { attrs } = useAttrs();
    const { instanceMsal } = useMsal();
    const store = useStore();
    const isAuthenticated = useIsAuthenticated();
    const isOpen = ref(props.value);
    const accountService = new AccountService();
    return {
      mobile,
      attrs,
      instanceMsal,
      isAuthenticated,
      store,
      isOpen,
      accountService,
    };
  },
  computed: {
    hasMultiCompanies() {
      return (
        this.store.state.currentUser?.companyAssociations &&
        this.store.state.currentUser?.companyAssociations.length > 1
      );
    },
  },
  methods: {
    async signIn() {
      this.isOpen = false;
      this.isLoading = true;
      await this.instanceMsal.loginRedirect(loginRequest);
      //TODO: PENDING REMOVAL. just in case we need popup in the future. I'm holding this for a bit.
      //      const authRes = await this.instanceMsal.loginPopup(loginRequest);
      //      await this.store.dispatch(eActions.setAccessToken, authRes.accessToken);
      //      await this.store.dispatch(eActions.setIdToken, authRes.idToken);
      //      const account = authRes.account;
      //      //TODO: mjb - this will create a stuck loading issue if there is a failure to retrieve account.
      //      if (account?.idTokenClaims) {
      //        const cmpAccount = await this.accountService.getAccountInfo(account.idTokenClaims.sub!);
      //        await this.store.dispatch(eActions.setCurrentUser, cmpAccount);
      //        const cmpCompany = await this.accountService.getCompanyInfo(cmpAccount.feCompanyId, cmpAccount.feCustomerId);
      //        await this.store.dispatch(eActions.setCurrentCompany, cmpCompany);
      //        await this.store.dispatch(eActions.setAccessType, eAppAccessType.AUTHENTICATED);
      //      }
      //      this.$router.push("/");
      //      this.isLoading = false;
    },
    async signOut() {
      this.isOpen = false;
      // todo: when we switching between accounts we have problem with msal coz it doesn't clean localStorage.
      // todo: it's bad practice so in future we should remove all msal fields here
      localStorage.clear();

      await this.instanceMsal.logoutRedirect();

      //todo: pending removal, holding until we know we won't need dual support. for redirect and popup.
      // await this.instanceMsal.logoutPopup({
      //   mainWindowRedirectUri: "/",
      // });
      //instruct app level auth/account tracking to logout.
      await this.store.dispatch(eActions.logout);
    },
    changeEmail() {
      this.instanceMsal.loginRedirect(changeEmailRequest);
    },
    changePassword() {
      this.instanceMsal.loginRedirect(loginChangePasswordRequest);
    },
    openPaymentMethods() {
      this.isOpen = false;
      this.$router.push("/payment-methods");
    },
    switchCompany() {
      this.isOpen = false;
      this.$router.push("/change-company");
    },
  },
});

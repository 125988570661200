import { AccountInfo } from "@azure/msal-browser";
import { Ref, ref, watch } from "vue";
import { useMsal } from "./useMsal";

/**
 * denotes if a user is signed-in or not.
 *
 * @returns boolean
 */
export function useIsAuthenticated(): Ref<boolean> {
  const { accounts } = useMsal();
  const isAuthenticated = ref(accounts.value.length > 0);

  watch(accounts, () => {
    isAuthenticated.value = accounts.value.length > 0;
  });

  return isAuthenticated;
}

//todo: mjb pending review/removal, used for navguard variant.
export function useIsAuthenticatedByAccounts(accounts: Ref<AccountInfo[]>): Ref<boolean> {
  const isAuthenticated = ref(accounts.value.length > 0);

  watch(accounts, () => {
    isAuthenticated.value = accounts.value.length > 0;
  });

  return isAuthenticated;
}


import { ICompanyCustomerPair, INavSection, IPropertyData } from "@/lib/shared-definitions";
import { defineComponent, PropType, ref, useAttrs } from "vue";
import { useDisplay } from "vuetify";
import MyAccountMenu from "@/components/MyAccountMenu.vue";
import PropertyMenu from "@/components/PropertyMenu.vue";
import { CreateUltraShortGuid } from "@/lib/utils";
import { EmptyProperty } from "@/lib/shared-constants";

export default defineComponent({
  name: "AppHeader",
  components: { MyAccountMenu, PropertyMenu },
  props: {
    showDrawerButton: {
      type: Boolean,
      default: true,
    },
    showDetails: {
      type: Boolean,
      default: false,
    },
    properties: {
      type: Array as PropType<Array<IPropertyData>>,
      default: () => {
        return [];
      },
    },
    currentProperty: {
      type: Object as PropType<IPropertyData>,
      require: true,
      default: () => {
        return EmptyProperty;
      },
    },
    currentCompany: {
      type: Object as PropType<ICompanyCustomerPair | null>,
      default: null,
    },
  },
  setup() {
    const { mobile } = useDisplay();
    const { attrs } = useAttrs();

    return { mobile, attrs };
  },
  emits: ["toggle-drawer", "update:modelValue", "update:currentProperty"],
  computed: {
    propertySelected: {
      get() {
        console.log("get: " + this.currentProperty?.entityId);
        return this.currentProperty;
      },
      set(value: IPropertyData) {
        console.log("set: " + value.entityId);
        this.$emit("update:currentProperty", value);
      },
    },

    companyNameUpdates() {
      return this.currentCompany?.company.name;
    },
    companyLogoPathUpdates() {
      return this.currentCompany?.company.logoUrl;
    },
    companyLogo() {
      return this.companyLogoPathUpdates ? this.companyLogoPathUpdates + "?img=" + CreateUltraShortGuid() : "";
    },
  },
  methods: {
    onToggleDrawer() {
      this.$emit("toggle-drawer");
    },
    openAlert(): void {
      this.showAlert = true;
      setTimeout(() => {
        this.showAlert = false;
      }, 5000);
    },
  },
  data: () => ({
    drawer: false,
    group: null,
    showAlert: false,
  }),
  watch: {
    group() {
      this.drawer = false;
    },
  },
});

import { LogLevel, PublicClientApplication, RedirectRequest } from "@azure/msal-browser";
import { EnvOptions } from "./envOptions";

export const msalConfig = {
  auth: {
    clientId: EnvOptions.B2cClientId,
    authority: EnvOptions.B2cLoginAuthority,
    redirectUri: EnvOptions.B2cRedirectUri,
    knownAuthorities: [EnvOptions.B2cKnownAuthority],
    //todo: mjb - pending removal, here for concept testing.
    postLogoutRedirectUri: EnvOptions.B2cRedirectUri,
  },
  cache: {
    cacheLocation: "localStorage",
  },
  system: {
    loggerOptions: {
      loggerCallback: (level: LogLevel, message: string, containsPii: boolean) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            return;
        }
      },
      logLevel: LogLevel.Verbose,
    },
  },
};

export const msalInstance = new PublicClientApplication(msalConfig);

//todo: pending removal. holding until we determine that we will not also need popup support.
// export const loginRequest: PopupRequest = {
//   //https://learn.microsoft.com/en-us/azure/active-directory-b2c/access-tokens#openid-connect-scopes
//   //the client Id is added as a scope to populate accesstoken on the auth result.
//   //scopes: ["openid", "profile", "offline_access", EnvOptions.B2cClientId],
//   scopes: ["openid", "profile", "offline_access"],
// };

export const loginRequest: RedirectRequest = {
  //https://learn.microsoft.com/en-us/azure/active-directory-b2c/access-tokens#openid-connect-scopes
  //the client Id is added as a scope to populate accesstoken on the auth result.
  //scopes: ["openid", "profile", "offline_access", EnvOptions.B2cClientId],
  scopes: ["openid", "profile", "offline_access"],
};

export const loginChangePasswordRequest: RedirectRequest = {
  authority: EnvOptions.B2cPasswordChangeAuthority,
  scopes: ["openid", "profile", "offline_access"],
};

export const changeEmailRequest: RedirectRequest = {
  authority: EnvOptions.B2cEmailChangeAuthority,
  scopes: ["openid", "profile", "offline_access"],
};

export const linkLoginRequest: RedirectRequest = {
  //https://learn.microsoft.com/en-us/azure/active-directory-b2c/access-tokens#openid-connect-scopes
  //the client Id is added as a scope to populate accesstoken on the auth result.
  //scopes: ["openid", "profile", "offline_access", EnvOptions.B2cClientId],
  scopes: ["openid", "profile", "offline_access"],
  redirectUri: EnvOptions.B2cRedirectUri + "/auth",
};

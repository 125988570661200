import {
  PaymentGatewayCapabilitiesModel,
  PostPaymentDTOModel,
  PostPaymentResponseModel,
} from "fieldedge-typescript-models-core";
import { PaymentInfoModel, PaymentInfoRequest } from "@/lib/models/WorkflowViewModels";
import { ePaymentGateway } from "fieldedge-typescript-models-core/models/base/PropertiesSystemBaseModel";
import { GenericActionFailedErrorMessage } from "@/lib/shared-definitions";
import { createAxiosCMP } from "@/lib/axios/cmpbackend";
import { AxiosError } from "axios";

import { createAxiosCMPPascalCase } from "@/lib/axios/cmpbackend";

//new functions
//getPaymentInfo will setup initial payment for the workOrder and save the workOrder and returns paymentInfo model
export async function getPaymentInfo(paymentInfoRequest: PaymentInfoRequest): Promise<PaymentInfoModel> {
  const ax = createAxiosCMP();
  return await ax
    .post(`get-payment-info`, paymentInfoRequest)
    .then((response) => {
      const { data } = response;
      return data;
    })
    .catch((error) => {
      if (error instanceof AxiosError) {
        console.error(error.message);
      } else console.error(error);
      throw { error: error, message: GenericActionFailedErrorMessage };
    });
}

export async function getPaymentSettings(companyId: string): Promise<PaymentGatewayCapabilitiesModel | null> {
  const ax = createAxiosCMPPascalCase();
  return await ax
    .get<PaymentGatewayCapabilitiesModel>(`/companies/${companyId}/payment`)
    .then((response) => {
      const { data } = response;
      return data;
    })
    .catch((error) => {
      if (error instanceof AxiosError) {
        console.error(error.message);
      } else console.error(error);
      return null;
    });
}

export async function PostPayment(
  companyId: string | null,
  paymentParams: PostPaymentDTOModel
): Promise<PostPaymentResponseModel> {
  const ax = createAxiosCMP();
  return await ax
    .post<PostPaymentResponseModel>(`companies/${companyId}/post-payment`, paymentParams)
    .then((response) => {
      const { data } = response;
      return data;
    })
    .catch((error) => {
      const returnValue = {
        success: false,
        error: true,
      } as PostPaymentResponseModel;

      if (error instanceof AxiosError) {
        console.error(error.message);
        returnValue.message = error.message;
      } else {
        console.error(error);
        returnValue.message = error;
      }
      return returnValue;
    });
}

export function isPaymentProviderConfigured(paymentSettings: PaymentGatewayCapabilitiesModel) {
  if (
    paymentSettings.PaymentGatewayType == ePaymentGateway.Clearent &&
    paymentSettings.HppPublicKey != null &&
    paymentSettings.HppPublicKey.length > 0
  ) {
    return true;
  } else if (
    paymentSettings.PaymentGatewayType == ePaymentGateway.Payroc &&
    paymentSettings.HppPublicKey != null &&
    paymentSettings.HppPublicKey.length > 0 &&
    paymentSettings.HppName != null &&
    paymentSettings.HppName.length > 0
  ) {
    return true;
  }
  return false;
}


import { useStore } from "@/store";
import BaseAlert from "./BaseAlert.vue";
import { eAlertType, ICmpAlert } from "./cmpAlertLib";
import { defineComponent, ref } from "vue";
import { useDisplay } from "vuetify/lib/framework.mjs";
import { EnvOptions } from "@/settings/envOptions";

/**
 * CmpTopLevelAlertPresenter is responsible for hooking into the vuex store and presenting an alert component for success or failure and
 * handling any interaction events with the alert. It is the default alert presenter the displays at a top level on the app's layout.
 * It is designed to be overridden by the display of alerts by the CmpInlineAlertPresenter.
 * See ./readme.md for more details
 */
export default defineComponent({
  components: { BaseAlert },
  name: "CmpTopLevelAlertPresenter",
  props: {
    isDrawer: {
      type: Boolean,
      default: false,
    },
    timeout: {
      type: Number,
      default: 5000,
    },
  },
  setup(props) {
    const { smAndUp } = useDisplay();
    let timeoutData = ref(props.timeout);
    const store = useStore();
    const { mobile } = useDisplay();

    const alertMessage = ref(
      !EnvOptions.TestAlerts
        ? ""
        : "top level presenter test: asdf sdaf afadsf dsf sdf asd fsda fasd fdas fsda fads fads fads fads fasd fasd f asdf af asdfasdf sdf d sfds fasdfas dfasd fads fdas fasd fasd f"
    );

    //note: this will be controlled by snackbar via v-model.
    const testAlerts = ref(EnvOptions.TestAlerts);
    if (testAlerts.value) {
      timeoutData.value = -1;
    }

    const alertType = ref(eAlertType.Success);

    return { mobile, store, timeoutData, smAndUp, alertMessage, showCmpAlert: testAlerts, alertType };
  },

  computed: {
    StoreCmpAlert() {
      return this.store.state?.cmpAlert;
    },
    drawer() {
      return this.store.state.navDrawerOpen;
    },
    isPageAlertPresenterActive() {
      return this.store.state.pageAlertPresenterActive;
    },
  },
  watch: {
    StoreCmpAlert(value) {
      if (value !== null && !this.isPageAlertPresenterActive) {
        let { alertMessage, alertType } = value as ICmpAlert;
        this.alertMessage = alertMessage;
        this.alertType = alertType !== null ? alertType : this.alertType;
        this.showCmpAlert = true;
      }
    },
  },
});

import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderSlot as _renderSlot, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createSlots as _createSlots } from "vue"

const _hoisted_1 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!
  const _component_v_overlay = _resolveComponent("v-overlay")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_item = _resolveComponent("v-item")!
  const _component_v_item_group = _resolveComponent("v-item-group")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_menu = _resolveComponent("v-menu")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_v_overlay, {
      modelValue: _ctx.isLoading,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isLoading) = $event)),
      scrim: false,
      transition: "none",
      persistent: true,
      class: "align-center justify-center loader"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_progress_circular, {
          indeterminate: "",
          color: "green",
          size: 65,
          width: 7
        })
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_v_menu, {
      modelValue: _ctx.isOpen,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.isOpen) = $event)),
      "close-on-content-click": false,
      location: "end"
    }, _createSlots({
      default: _withCtx(() => [
        _createVNode(_component_v_list, null, {
          default: _withCtx(() => [
            (_ctx.isAuthenticated)
              ? (_openBlock(), _createBlock(_component_v_item_group, { key: 0 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_list_item, { class: "property-title property-item-style" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_list_item_title, null, {
                          default: _withCtx(() => [
                            _createTextVNode("Properties")
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_divider),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.properties, (property) => {
                      return (_openBlock(), _createBlock(_component_v_list_item, {
                        class: _normalizeClass(["property-item-style", { 'property-selected': property.entityId === _ctx.propertySelected.entityId }]),
                        key: property.entityId,
                        link: ""
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_item, null, {
                            default: _withCtx(() => [
                              _createElementVNode("div", {
                                onClick: ($event: any) => (_ctx.selectProperty(property))
                              }, _toDisplayString(_ctx.propertyName(property)), 9, _hoisted_1)
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _: 2
                      }, 1032, ["class"]))
                    }), 128))
                  ]),
                  _: 1
                }))
              : (_openBlock(), _createBlock(_component_v_list_item, {
                  key: 1,
                  link: "",
                  style: {"width":"212px"}
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_list_item_title, null, {
                      default: _withCtx(() => [
                        _createTextVNode("No properties available.")
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }))
          ]),
          _: 1
        })
      ]),
      _: 2
    }, [
      (_ctx.properties.length == 0)
        ? {
            name: "emptyActivator",
            fn: _withCtx(({ props }) => [
              _renderSlot(_ctx.$slots, "activator", {
                menuProps: props,
                propertySelected: _ctx.propertySelected,
                properties: _ctx.properties
              })
            ]),
            key: "0"
          }
        : {
            name: "activator",
            fn: _withCtx(({ props }) => [
              _renderSlot(_ctx.$slots, "activator", {
                props: props,
                propertySelected: _ctx.propertySelected,
                properties: _ctx.properties
              })
            ]),
            key: "1"
          }
    ]), 1032, ["modelValue"])
  ], 64))
}
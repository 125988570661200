import { RouteLocationNormalized, Router } from "vue-router";

import { store } from "../store";
import { eAppAccessType } from "@/lib/shared-definitions";

/**
 * guards route access based on store.state.accessType
 * @param router
 */
export function registerAccessTypeGuard(router: Router) {
  router.beforeEach(async (to: RouteLocationNormalized) => {
    console.log("to.name:" + to.name?.toString());
    if (!to.meta.requiresAuth || (to.meta.requiresAuth && store.state.accessType === eAppAccessType.AUTHENTICATED)) {
      return true;
    } else {
      return "not-authorized";
    }
  });
}

//todo: pending removal
// export function registerGuard(router: Router) {
//   router.beforeEach(async (to: RouteLocationNormalized, from: RouteLocationNormalized) => {
//     console.log("to.name:" + to.name?.toString());
//     if (to.meta.requiresAuth) {
//       const request = {
//         ...loginRequest,
//         redirectStartPage: to.fullPath,
//       };
//       const shouldProceed = await isAuthenticated(msalInstance, InteractionType.Popup, request);
//       return shouldProceed || "/not-authorized";
//     } else {
//       //for non-auth pages. we can still improve user experience if they happen to be auth'ed. lets check.
//       try {
//         const res = await msalInstance.acquireTokenSilent(loginRequest);
//         await store.dispatch(eActions.setIdToken, res.idToken);
//       } catch (err) {
//         //keep silent. auth not required.
//       }
//     }

//     return true;
//   });
// }
// async function isAuthenticated(
//   instance: PublicClientApplication,
//   interactionType: InteractionType,
//   loginRequest: PopupRequest | RedirectRequest
// ): Promise<boolean> {
//   const loginInfo = await getLoginAuth(instance, interactionType, loginRequest);
//   if (loginInfo === null) {
//     //no or bad auth.
//     return false;
//   } else if (loginInfo.interactionType === InteractionType.Redirect) {
//     return true;
//   } else if (loginInfo.result === null) {
//     //only redirect interaction type should have a null result.
//     //todo: used structured error types.
//     throw new Error("missing account data");
//   } else {
//     try {
//       //ok by this point we should have a valid id token to use with requests.
//       //lets set it.
//       await store.dispatch(eActions.setIdToken, loginInfo.result.idToken);

//       const account = loginInfo.result.account;
//       if (!account || !account.idTokenClaims || !account.idTokenClaims.sub) {
//         throw new Error("missing account id");
//       }
//       const accountService = new AccountService();

//       const accountInfo = await accountService.getAccountInfo(account.idTokenClaims.sub);
//       await store.dispatch(eActions.setCurrentUser, accountInfo);

//       try {
//         const companyInfo = await accountService.getCompanyInfo(accountInfo.feCompanyId, accountInfo.feCustomerId);
//         await store.dispatch(eActions.setCurrentCompany, companyInfo);
//         return true;
//       } catch (err) {
//         //todo: handle axios error and app level errors
//         //for more informative error experience for user.
//         //currently this will just look like an access issue.
//         return false;
//       }
//     } catch (err) {
//       //todo: handle axios error and app level errors
//       //for more informative error experience for user.
//       //currently this will just look like an access issue.
//       return false;
//     }
//   }
// return (
//   instance
//     // If your application uses redirects for interaction, handleRedirectPromise must be called and awaited on each page load before determining if a user is signed in or not
//     //.handleRedirectPromise()
//     .acquireTokenSilent(loginRequest)
//     .then(() => {
//       const accounts = instance.getAllAccounts();
//       if (accounts.length > 0) {
//         return true;
//       }

//       // User is not signed in and attempting to access protected route. Sign them in.
//       if (interactionType === InteractionType.Popup) {
//         return instance
//           .loginPopup(loginRequest)
//           .then(() => {
//             return true;
//           })
//           .catch(() => {
//             return false;
//           });
//       } else if (interactionType === InteractionType.Redirect) {
//         return instance
//           .loginRedirect(loginRequest)
//           .then(() => {
//             return true;
//           })
//           .catch(() => {
//             return false;
//           });
//       }

//       return false;
//     })
//     .catch(() => {
//       return false;
//     })
// );
// }

// async function getLoginAuth(
//   instance: PublicClientApplication,
//   interactionType: InteractionType,
//   loginRequest: PopupRequest | RedirectRequest
// ): Promise<{ interactionType: InteractionType; result: AuthenticationResult | null } | null> {
//   try {
//     const result = await instance.acquireTokenSilent(loginRequest);
//     return { interactionType, result };
//   } catch (err) {
//     // User is not signed in and attempting to access protected route. Sign them in.
//     if (interactionType === InteractionType.Popup) {
//       try {
//         const result = await instance.loginPopup(loginRequest);
//         return { interactionType, result };
//       } catch {
//         return null;
//       }
//     } else if (interactionType === InteractionType.Redirect) {
//       try {
//         //WARNING!! this will reload the page.. return can't be relied on.
//         await instance.loginRedirect(loginRequest);
//         return { interactionType, result: null };
//       } catch {
//         return null;
//       }
//     } else {
//       throw new Error("unsupported interaction type.");
//     }
//   }
// }

// export function registerGuardv2(router: Router) {
//   router.beforeEach(async (to: RouteLocationNormalized) => {
//     const shouldProceed = await isAuthenticatedv2(msalInstance);

//     if (to.name !== "not-authorized" && to.name !== "auth-redirect" && !shouldProceed)
//       return {
//         path: "/not-authorized",
//       };
//     return true;
//   });
// }

// export async function isAuthenticatedv2(instance: PublicClientApplication) {
//   return instance
//     .handleRedirectPromise()
//     .then(() => {
//       const accounts = instance.getAllAccounts();
//       if (accounts.length > 0) {
//         return true;
//       }

//       return false;
//     })
//     .catch(() => {
//       return false;
//     });
// }

import { InjectionKey } from "vue";
import { createStore, useStore as baseUseStore, Store } from "vuex";

import { eAppAccessType, IAccountB2C, ICompanyCustomerPair, IPropertyData } from "@/lib/shared-definitions";
import { ICmpAlert } from "@/components/CmpAlerts/cmpAlertLib";
import { IWorkflowState, WorkflowModule } from "./modules/workflow";
import { EmptyProperty } from "@/lib/shared-constants";

export enum ePaymentStatus {
  NOT_SET = "notset",
  SUCCESS = "success",
  FAILED = "failed",
}

export enum eQuoteSelectedStatus {
  NOT_SET = "notset",
  ACCEPTED = "Accepted",
}
// TODO: review what we'll store in state tracking.
// This initial state fields are a draft.
export interface IState {
  serverOnline: boolean;
  triggerAuth: boolean;
  /**current accessToken to use when for authentication with CMPBackend*/
  accessToken: string;
  idToken: string;
  /**current user from azure B2C*/
  currentUser: IAccountB2C | undefined;
  /**The company/customer pair related to the current profile selected by the customer */
  currentCompany: ICompanyCustomerPair | undefined;

  /** possible  */
  companies: Array<ICompanyCustomerPair>;

  /** Used to control whether the app displays in a more stylized mode.
   * - Currently this refers to rounded corners on main page views.
   */
  displayStylized: boolean;
  accessType: eAppAccessType;
  locale: string;
  cmpAlert: ICmpAlert | undefined;
  /** used to track and control payment integration related behavior such as status alert triggers.*/
  paymentStatus: ePaymentStatus;
  timezoneOffset: number;
  quoteSelectionStatus: eQuoteSelectedStatus;

  /**supplies the sub-state object for the workflow state module*/
  workflowState: IWorkflowState;
  properties: Array<IPropertyData>;
  currentProperty: IPropertyData;
  openFilterPanel: boolean;
  openMobileFilterModal: boolean;

  navDrawerOpen: boolean;
  pageAlertPresenterActive: boolean;
}

export const key: InjectionKey<Store<IState>> = Symbol();

/**
 * enumerations of app's vuex actions that can be used with
 * store.state.dispatch.
 * @example store.state.dispatch(eActions.logout)
 */
export enum eActions {
  /**triggers msal auth check and possible popup. */
  setTriggerAuth = "setTriggerAuth",
  /** wip: used to denote cmpbackend is offline/online */
  setServerOnline = "setServerOnline",
  setPaymentStatus = "setPaymentStatus",
  /**used to display in ui stylized mode. aka left nav / main view area radius edges */
  setDisplayStylized = "setDisplayStylized",
  /**b2c/msal idToken used for api access with cmpbackend */
  setIdToken = "setIdToken",
  /**tbd: may be needed for ms graph access*/
  setAccessToken = "setAccessToken",
  /**controls app access state mechanics*/
  setAccessType = "setAccessType",
  setCurrentUser = "setCurrentUser",
  setCurrentCompany = "setCurrentCompany",
  /**triggers a CmpAlert to be displayed.  */
  setCmpAlert = "setCmpAlert",
  setTimezoneOffset = "setTimezoneOffset",
  /**logout app level user account/auth data (does not include msal) */
  logout = "logout",

  /**set the sub-state for workflow module */
  setWorkFlowState = "setWorkFlowState",

  setQuoteSelectionStatus = "setQuoteSelectionStatus",

  /**call setId of workflow module */ /* when the module is set to namespaced = true, exact name of the module must be added before the name of the action or mutation itself */
  workflowResetState = "workflowState/resetState",
  workflowSetWorkOrderId = "workflowState/setWorkOrderId",
  workflowSetOriginalWorkOrder = "workflowState/setOriginalWorkOrder",
  /**call setCompanyId of workflow module */
  workflowSetCompanyId = "workflowState/setCompanyId",
  workflowSetDepositPercent = "workflowState/setDepositPercent",
  setProperties = "setProperties",
  setCurrentProperty = "setCurrentProperty",
  setShowConfirmation = "setShowConfirmation",
  setOpenFilterPanel = "setOpenFilterPanel",
  setOpenMobileFilterModal = "setOpenMobileFilterModal",

  /**called to share the open state of the nav drawer */
  setNavDrawerOpen = "setNavDrawerOpen",

  setPageAlertPresenterActive = "setPageAlertPresenterActive",
}

//TODO: using store.state.dispatch('someAction',someValue) is clumsy.
//for improved readability/usability of the state api we should wrap these so that
//intellisense available. or use another system/approach. pino?
export const store = createStore<IState>({
  modules: {
    workflowState: WorkflowModule,
  },

  //Note: type cast "as unknown as IState" is used to work around module warning of state value override.
  state: {
    triggerAuth: false,
    serverOnline: false,
    accessToken: "",
    idToken: "",
    currentUser: undefined,
    currentCompany: undefined,
    companies: [],
    displayStylized: false,
    accessType: eAppAccessType.NOT_SET,
    //TODO: Possible get locale from company settings using AccountingRegion ?
    locale: "en-US",
    cmpAlert: undefined,
    paymentStatus: ePaymentStatus.NOT_SET,
    quoteSelectionStatus: eQuoteSelectedStatus.NOT_SET,
    timezoneOffset: 0,
    // Note: This causes: [vuex] state field "workflowState" was overridden by a module with the same name at "workflowState"
    // We could comment this "workflowState" prop out  then apply a cast chain of " as unknown as IState" to the state object.
    // This would remove the warning by causing the state system to not have a pre-existing initialized value for the prope. BUT this would hide legit issues.
    // So for now we'll need to keep this warning until we find a fix that will not degrade State initialization build time checks.
    workflowState: {} as IWorkflowState, // this must have the same name as the name of the associated module in the modules property above otherwise the module will not be accessible through the store state
    properties: [] as IPropertyData[],
    currentProperty: EmptyProperty,
    openFilterPanel: false,
    openMobileFilterModal: false,

    navDrawerOpen: false,
    pageAlertPresenterActive: false,
  },
  mutations: {
    setTriggerAuth(state, val: boolean) {
      state.triggerAuth = val;
    },

    setServerOnline(state, val: boolean) {
      state.serverOnline = val;
    },

    setPaymentStatus(state, val: ePaymentStatus) {
      state.paymentStatus = val;
    },
    setQuoteSelectionStatus(state, val: eQuoteSelectedStatus) {
      state.quoteSelectionStatus = val;
    },
    setDisplayStylized(state, val: boolean) {
      state.displayStylized = val;
    },

    setIdToken(state, val: string) {
      state.idToken = val;
    },

    setAccessToken(state, val: string) {
      state.accessToken = val;
    },

    setAccessType(state, val: eAppAccessType) {
      state.accessType = val;
    },

    setCurrentUser(state, val: IAccountB2C) {
      state.currentUser = val;
    },

    setCurrentCompany(state, val: ICompanyCustomerPair) {
      state.currentCompany = val;
    },

    setCmpAlert(state, val: ICmpAlert) {
      state.cmpAlert = val;
    },

    setWorkFlowState(state, val: IWorkflowState) {
      state.workflowState = val;
    },

    setProperties(state, val: IPropertyData[]) {
      state.properties = val;
    },

    setCurrentProperty(state, val: IPropertyData) {
      state.currentProperty = val;
    },

    setTimezoneOffset(state, val: number) {
      state.timezoneOffset = val;
    },
    setOpenFilterPanel(state, val: boolean) {
      state.openFilterPanel = val;
    },
    setOpenMobileFilterModal(state, val: boolean) {
      state.openMobileFilterModal = val;
    },

    setNavDrawerOpen(state, val: boolean) {
      state.navDrawerOpen = val;
    },

    setPageAlertPresenterActive(state, val: boolean) {
      state.pageAlertPresenterActive = val;
    },
  },
  actions: {
    setTriggerAuth({ commit }, val) {
      commit(eActions.setTriggerAuth, val);
    },

    setServerOnline({ commit }, val) {
      commit(eActions.setServerOnline, val);
    },

    setPaymentStatus({ commit }, val) {
      commit(eActions.setPaymentStatus, val);
    },

    setQuoteSelectionStatus({ commit }, val) {
      commit(eActions.setQuoteSelectionStatus, val);
    },

    setDisplayStylized({ commit }, val) {
      commit(eActions.setDisplayStylized, val);
    },

    setIdToken({ commit }, val) {
      commit(eActions.setIdToken, val);
    },

    setAccessToken({ commit }, val) {
      commit(eActions.setAccessToken, val);
    },

    setAccessType({ commit }, val) {
      commit(eActions.setAccessType, val);
    },

    setCurrentUser({ commit }, val) {
      commit(eActions.setCurrentUser, val);
    },

    setCurrentCompany({ commit }, val) {
      commit(eActions.setCurrentCompany, val);
    },

    setCmpAlert({ commit }, val) {
      commit(eActions.setCmpAlert, val);
    },
    logout({ commit }) {
      commit(eActions.setAccessToken, null);
      commit(eActions.setIdToken, null);
      commit(eActions.setCurrentCompany, null);
      commit(eActions.setCurrentUser, null);
      commit(eActions.setAccessType, eAppAccessType.PUBLIC);
    },
    setTimezoneOffset({ commit }, val) {
      commit(eActions.setTimezoneOffset, val);
    },

    setWorkFlowState({ commit }, val) {
      commit(eActions.setWorkFlowState, val);
    },

    setProperties({ commit }, val) {
      commit(eActions.setProperties, val);
    },

    setCurrentProperty({ commit }, val) {
      commit(eActions.setCurrentProperty, val);
    },
    setOpenFilterPanel({ commit }, val) {
      commit(eActions.setOpenFilterPanel, val);
    },
    setOpenMobileFilterModal({ commit }, val) {
      commit(eActions.setOpenMobileFilterModal, val);
    },

    setNavDrawerOpen({ commit }, val) {
      commit(eActions.setNavDrawerOpen, val);
    },

    setPageAlertPresenterActive({ commit }, val) {
      commit(eActions.setPageAlertPresenterActive, val);
    },
  },
});

// define your own `useStore` composition function
export function useStore() {
  return baseUseStore(key);
}

/**
 * the purpose of this file is to hold special case shared constant values used by the app.
 */
import { IPropertyData } from "./shared-definitions";

/**
 * A special property used to represent a desire to use/see "All Properties"
 * in a context.
 */
export const AllDisplayProperty: IPropertyData = {
  address1: "All Properties",
  address2: "",
  city: "",
  state: "",
  zip: "",
  entityId: "10000000-0000-0000-0000-000000000000",
};

/**
 * A special empty property used for state/prop initialization in cases were variable reactivity is needed.
 * Why?, when properties/state objects are initialized to null they can be excluded from reactivity functionality.
 */
export const EmptyProperty: IPropertyData = {
  address1: "",
  address2: "",
  city: "",
  entityId: "00000000-0000-0000-0000-000000000000",
  state: "",
  zip: "",
};

export const EmptyGuid = "00000000-0000-0000-0000-000000000000";

export const QBDPaymentID = "a942aba4-1c44-408e-9890-b6366413d401";

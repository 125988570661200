import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseAlert = _resolveComponent("BaseAlert")!
  const _component_v_snackbar = _resolveComponent("v-snackbar")!

  return (!_ctx.isPageAlertPresenterActive)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        true
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(_ctx.smAndUp ? ['d-flex', 'justify-center', 'align-center'] : ['d-flex', 'flex-row', 'flex-wrap'])
            }, [
              _createVNode(_component_v_snackbar, {
                modelValue: _ctx.showCmpAlert,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.showCmpAlert) = $event)),
                color: "surface",
                rounded: _ctx.smAndUp ? 'xl' : 'lg',
                location: _ctx.smAndUp ? 'top' : 'bottom',
                width: _ctx.smAndUp ? 476 : 296,
                "min-width": _ctx.smAndUp ? 476 : 296,
                "location-strategy": "connected",
                style: _normalizeStyle(
          _ctx.smAndUp
            ? { left: _ctx.isDrawer ? '23.5% !important' : '', top: '1% !important' }
            : { left: '', bottom: '64px !important' }
        ),
                "content-class": !_ctx.smAndUp ? 'cmp-alert-mobile elevation-0' : 'cmp-alert elevation-5',
                timeout: _ctx.timeoutData
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_BaseAlert, {
                    alertMessage: _ctx.alertMessage,
                    alertType: _ctx.alertType
                  }, null, 8, ["alertMessage", "alertType"])
                ]),
                _: 1
              }, 8, ["modelValue", "rounded", "location", "width", "min-width", "style", "content-class", "timeout"])
            ], 2))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}
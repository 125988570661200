import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CmpTopLevelAlertPresenter = _resolveComponent("CmpTopLevelAlertPresenter")!
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!
  const _component_v_overlay = _resolveComponent("v-overlay")!
  const _component_NavDrawer = _resolveComponent("NavDrawer")!
  const _component_AppHeader = _resolveComponent("AppHeader")!
  const _component_BottomNav = _resolveComponent("BottomNav")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_v_main = _resolveComponent("v-main")!
  const _component_v_app = _resolveComponent("v-app")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_CmpTopLevelAlertPresenter, { "is-drawer": _ctx.drawer }, null, 8, ["is-drawer"]),
    _createVNode(_component_v_overlay, {
      modelValue: _ctx.isLoading,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isLoading) = $event)),
      scrim: false,
      transition: "none",
      persistent: true,
      class: "align-center justify-center"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_progress_circular, {
          indeterminate: "",
          color: "green",
          size: 65,
          width: 7
        })
      ]),
      _: 1
    }, 8, ["modelValue"]),
    (!_ctx.isLoading)
      ? (_openBlock(), _createBlock(_component_v_app, {
          key: 0,
          id: "inspire"
        }, {
          default: _withCtx(() => [
            (!_ctx.$route.meta.hideNavbar)
              ? (_openBlock(), _createBlock(_component_NavDrawer, {
                  key: 0,
                  modelValue: _ctx.drawer,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.drawer) = $event)),
                  "current-property": _ctx.currentProperty,
                  "onUpdate:current-property": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.currentProperty) = $event)),
                  "access-type": _ctx.accessType,
                  "nav-sections": _ctx.navSections,
                  "user-data": _ctx.user,
                  "bg-color": _ctx.theme.current.value.colors.background,
                  properties: _ctx.properties,
                  "current-company": _ctx.currentCompany
                }, null, 8, ["modelValue", "current-property", "access-type", "nav-sections", "user-data", "bg-color", "properties", "current-company"]))
              : _createCommentVNode("", true),
            (!_ctx.$route.meta.hideNavbar && (_ctx.mobile || _ctx.sm))
              ? (_openBlock(), _createBlock(_component_AppHeader, {
                  key: 1,
                  "current-property": _ctx.currentProperty,
                  "onUpdate:current-property": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.currentProperty) = $event)),
                  "show-drawer-button": _ctx.sm,
                  "show-details": !(_ctx.sm && _ctx.drawer),
                  properties: _ctx.properties,
                  "current-company": _ctx.currentCompany,
                  onToggleDrawer: _ctx.onToggleDrawer
                }, null, 8, ["current-property", "show-drawer-button", "show-details", "properties", "current-company", "onToggleDrawer"]))
              : _createCommentVNode("", true),
            (!_ctx.$route.meta.hideNavbar && _ctx.mobile && !_ctx.smAndUp && !_ctx.store.state.openFilterPanel)
              ? (_openBlock(), _createBlock(_component_BottomNav, {
                  key: 2,
                  links: _ctx.navSections[0].links
                }, null, 8, ["links"]))
              : _createCommentVNode("", true),
            _createVNode(_component_v_main, {
              class: "pt-0",
              style: _normalizeStyle({ 'background-color': _ctx.mobile ? 'white' : 'inherit' })
            }, {
              default: _withCtx(() => [
                _createVNode(_component_router_view)
              ]),
              _: 1
            }, 8, ["style"])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 64))
}
import { INavSection, eAppAccessType, eIconPosition } from "./shared-definitions";

export const NavigationSections: Array<INavSection> = [
  {
    links: [
      {
        mobileIconPath: require("./../assets/NavigationMobileIcons/snapshot-mobile.svg"),
        mobileIconPathActive: require("./../assets/NavigationMobileIcons/snapshot-mobile-active.svg"),
        webIconPath: require("./../assets/NavigationWebIcons/snapshot.svg"),
        webIconPathActive: require("./../assets/NavigationWebIcons/snapshot-active.svg"),
        name: "Snapshot",
        path: "/snapshot",
        iconPosition: eIconPosition.LEFT,
        accessTypes: [eAppAccessType.AUTHENTICATED],
        isActive: true,
      },
      {
        mobileIconPath: require("./../assets/NavigationMobileIcons/appointments-mobile.svg"),
        mobileIconPathActive: require("./../assets/NavigationMobileIcons/appointments-mobile-active.svg"),
        webIconPath: require("./../assets/NavigationWebIcons/appointments.svg"),
        webIconPathActive: require("./../assets/NavigationWebIcons/appointments-active.svg"),
        name: "Appointments",
        path: "/appointments",
        iconPosition: eIconPosition.LEFT,
        accessTypes: [eAppAccessType.AUTHENTICATED],
      },
      {
        mobileIconPath: require("./../assets/NavigationMobileIcons/invoices-mobile.svg"),
        mobileIconPathActive: require("./../assets/NavigationMobileIcons/invoices-mobile-active.svg"),
        webIconPath: require("./../assets/NavigationWebIcons/invoices.svg"),
        webIconPathActive: require("./../assets/NavigationWebIcons/invoices-active.svg"),
        name: "Invoices",
        path: "/invoices",
        iconPosition: eIconPosition.LEFT,
        accessTypes: [eAppAccessType.AUTHENTICATED],
      },
      {
        mobileIconPath: require("./../assets/NavigationMobileIcons/quotes-mobile.svg"),
        mobileIconPathActive: require("./../assets/NavigationMobileIcons/quotes-mobile-active.svg"),
        webIconPath: require("./../assets/NavigationWebIcons/quotes.svg"),
        webIconPathActive: require("./../assets/NavigationWebIcons/quotes-active.svg"),
        name: "Quotes",
        path: "/quotes",
        iconPosition: eIconPosition.LEFT,
        accessTypes: [eAppAccessType.AUTHENTICATED],
      },
    ],
  },
];

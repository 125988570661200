import { eInvoicePayStatus } from "fieldedge-typescript-models-core/models/base/InvoiceBaseModel";
export enum eAppAccessType {
  NOT_SET = 0,
  PUBLIC = 1,
  AUTHENTICATED = 2,
}

export interface ICompanyData {
  id: string;
  name: string;
  logoUrl: string;
  locale: string;
  depositPercent: number;
}

export interface ICompanyAccountData {
  company: ICompanyData;
  customer: ICustomerInfo;
}

export interface ISelectedCompanyData extends ICompanyData {
  selected: boolean;
}

export interface ISelectedCompanyAccountData extends ICompanyAccountData {
  selected: boolean;
}

export interface INavSection {
  name?: string;
  links: Array<INavLink>;
}

export enum eIconPosition {
  LEFT = 1,
  RIGHT = 2,
}

export enum eQuoteStatus {
  None,
  Pending,
  Accepted,
  Scheduled,
  Rejected,
  //TODO: need to consider adding to FE enum eg: Expired = 5
  Expired = 100,
}

export enum eAppointmentStatus {
  Upcoming,
  Past,
  Requested,
}

export enum ePaymentMethodCategoryType {
  Card = 0,
  Account = 1,
}

export interface INavLink {
  icon?: string;
  mobileIconPath?: any;
  mobileIconPathActive?: any;
  webIconPath?: any;
  webIconPathActive?: any;
  name: string;
  path: string;
  iconPosition: eIconPosition;
  accessTypes: eAppAccessType[];
  isActive?: false | true;
}

export const eIconPositionMixin = {
  methods: {
    isLeftPosition: function (val: eIconPosition): boolean {
      return val === eIconPosition.LEFT;
    },

    isRightPosition: function (val: eIconPosition): boolean {
      return val === eIconPosition.RIGHT;
    },
  },
};

export interface IInvoiceListResponse {
  invoices: Array<IInvoiceCardData>;
}

export interface IInvoiceCardData {
  id: string;
  invoiceNumber: string;
  paid: boolean;
  quoteAttached: boolean;
  task: string;
  paymentDueOn?: Date;
  totalAmount: number;
  paidAmount: number;
  outstandingAmount: number;
  property: string;
  paidOn?: Date;
  paymentMethod?: string;

  /** date owning dispatch record was created */
  dateAdded?: Date;
  /** date invoice was created, essentially */
  transactionDate?: Date;
  /**eg: en-US, ca  */
  locale?: string;
  signedSentDatetime?: Date;
  signedDatetime?: Date;
  payStatus: eInvoicePayStatus;
}

export interface IAppointmentCardData {
  appointmentNumber: string;
  appointmentDate: string;
  timeFrame: string;
  task: string;
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  zip?: string;
  quoteNumber: string;
  invoiceNumber: string;
  status: eAppointmentStatus;
  dateCreated?: string;
  dateRequested?: string;
  contactFirstName?: string;
  contactLastName?: string;
  description?: string;
  isMissed: boolean;
  /**eg: en-US, ca  */
  locale?: string;
}

export interface IQuoteCardData {
  quoteNumber: string;
  expirationDate?: string;

  /**date owning dispatch record was added */
  dateAdded?: string;

  /** date quote was created essentially */
  transactionDate?: string;

  signedDatetime?: string;
  total: number;
  balance: number;
  depositAmount: number;
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  zip?: string;
  status: eQuoteStatus;
  invoiceEntered: boolean;
  workOrderID: string;
  /**eg: en-US, ca  */
  locale?: string;
  signedSentDatetime?: Date;
}

export enum eDateMacro {
  Macro_Today = 0,
  Macro_Yesterday = 1,
  Macro_Tomorrow = 2,
  Macro_ThisWeek = 3,
  Macro_ThisWeekToDate = 4,
  Macro_ThisMonth = 5,
  Macro_ThisMonthToDate = 6,
  Macro_ThisYear = 7,
  Macro_ThisYearToDate = 8,
  Macro_NextWeek = 9,
  Macro_NextMonth = 10,
  Macro_LastWeek = 11,
  Macro_LastMonth = 12,
  Macro_Last7Days = 13,
  Macro_Last15Days = 14,
  Macro_Custom = 15,
  Macro_Last30Days = 16,
  Macro_Next7Days = 17,
  Macro_InThePast = 18,
  Macro_All = 99,
}

export const MacroValuesList = [
  { name: "All", code: eDateMacro.Macro_Today, dateType: 0 },
  { name: "Custom", code: eDateMacro.Macro_Custom, dateType: 0 },
  { name: "Today", code: eDateMacro.Macro_Today, dateType: 0 },
  { name: "Yesterday", code: eDateMacro.Macro_Yesterday, dateType: 24 },
  { name: "Tomorrow", code: eDateMacro.Macro_Tomorrow, dateType: 23 },
  { name: "This week", code: eDateMacro.Macro_ThisWeek, dateType: 23 },
  { name: "This week to date", code: eDateMacro.Macro_ThisWeekToDate, dateType: 24 },
  { name: "This month", code: eDateMacro.Macro_ThisMonth, dateType: 23 },
  { name: "This month to date", code: eDateMacro.Macro_ThisMonthToDate, dateType: 24 },
  { name: "This year", code: eDateMacro.Macro_ThisYear, dateType: 23 },
  { name: "This year to date", code: eDateMacro.Macro_ThisYearToDate, dateType: 24 },
  { name: "Next week", code: eDateMacro.Macro_NextWeek, dateType: 23 },
  { name: "Next month", code: eDateMacro.Macro_NextMonth, dateType: 23 },
  { name: "Last week", code: eDateMacro.Macro_LastWeek, dateType: 24 },
  { name: "Last month", code: eDateMacro.Macro_LastMonth, dateType: 24 },
  { name: "Last 7 days", code: eDateMacro.Macro_Last7Days, dateType: 24 },
  { name: "Last 15 days", code: eDateMacro.Macro_Last15Days, dateType: 24 },
  { name: "Last 30 days", code: eDateMacro.Macro_Last30Days, dateType: 24 },
  { name: "In the past", code: eDateMacro.Macro_InThePast, dateType: 24 },
];

export const MacroValuesNames = [
  "All",
  "Custom",
  "Today",
  "Yesterday",
  "Tomorrow",
  "This week",
  "This week to date",
  "This month",
  "This month to date",
  "This year",
  "This year to date",
  "Next week",
  "Next month",
  "Last week",
  "Last month",
  "Last 7 days",
  "Last 15 days",
  "Last 30 days",
  "In the past",
];

/**
 * @deprecated
 */
export interface IFilterQuoteData {
  status: eQuoteStatus;
  paymentDue: boolean;
  dateFrom: string;
  dateTo?: string;
  propertyFrom: string;
  propertyTo: string;
}

export interface IFilterData {
  quoteStatus?: eQuoteStatus;
  appointmentStatus?: eAppointmentStatus;
  paymentDue?: boolean;
  dateFrom?: string;
  dateTo?: string;
  dateRangeType?: eDateMacro;
  dateRangeName?: string;
  propertyFrom?: string;
  propertyTo?: string;
  amountFrom?: number;
  amountTo?: number;
}

export interface ICustomerData {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  displayName: string;
  appointmentDate?: Date;
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  zip?: string;
  phone?: string;
  company?: string;
  totalAmountOwed?: number;
  closestPaymentDue?: Date;
  quotes: IQuoteCardData[];
}

export interface ICustomerInfo {
  id: string;
  firstName: string;
  email: string;
  lastName: string;
  name: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
}

export interface IAppointmentData {
  appointmentDate: string;
}

export interface IInvoiceSummary {
  total: string;
  recCount: string;
  dueDate: string;
}

export interface ISnapshotData {
  customerInformation: ICustomerInfo;
  dispatchTaskAppointmentInfo: IAppointmentData;
  invoiceSummary: IInvoiceSummary;
  consumerManagementPortalQuotes: IQuoteCardData[];
}

export interface ICompanyCustomerPair {
  company: ICompanyData;
  customer: ICustomerData;
}

export interface IPaymentMethodData {
  logo?: string;
  name?: string;
  number?: string;
  expirationDate?: Date;
  ownerName?: string;
  methodType: ePaymentMethodCategoryType;
  isUsedInAutoPay: boolean;
}

export interface IAccountB2C {
  /**b2c object id, should always be available */
  id: string;
  feCompanyId: string | unknown;
  feCustomerId: string | unknown;
  email: string | unknown;
  idProvider: string | unknown;
  companyAssociations: ICompanyAssociations[];
}

export interface ICompanyAssociations {
  feCompanyId: string;
  feCustomerId: string;
}

export interface IInvoiceListRequest {
  PaymentDue: boolean;
  PropertyIds?: [];
  FromDate?: string;
  ToDate?: string;
  FromValue?: string;
  ToValue?: string;
  CompanyId: string;
  CustomerId: string;
  Active?: boolean;
  TimezoneOffset?: number;
}

export interface ISnapshotInfoRequest {
  CompanyId: string;
  CustomerId: string;
  TimezoneOffset?: number;
  PropertyIds?: [];
}

export interface IPropertiesInfoResponse {
  properties: Array<IPropertyData>;
}

export interface IPropertyData {
  entityId: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  isJob?: boolean;
}

export interface IAppointmentPropertyData {
  entityId?: string;
  line1?: string;
  line2?: string;
  city?: string;
  state?: string;
  zip?: string;
  isJob?: boolean;
}

export interface IContactData {
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  email?: string;
  phone?: string;
  company?: string;
}

export interface IAppointmentRequestData {
  address: IAppointmentPropertyData;
  contact: IContactData;
  requestId: string;
  dateAdded?: Date;
  task: string;
  desiredDate: string;
  preferredContactMethodPhone: boolean;
  preferredContactMethodEmail: boolean;
  newAppointmentRequest(obj: IPropertyData, contact?: ICustomerData | IContactData): void;
}

export interface IProgressStepperItem {
  key: number | string;
  label: string;
  step: number;
  disabled: boolean;
}

export enum ePaymentOption {
  MinimumAmount,
  TotalBalance,
  OtherAmount,
  PayLater,
}

export enum eWorkflowStep {
  ReviewView = "review",
  PayOptionView = "pay-options",
  CheckoutView = "checkout",
  QuoteOptionView = "quote-options",
  SignatureView = "signature",
}

export interface IWorkflowViewData {
  id: string;
  title: string;
}

export interface IWorkflowItem {
  name: string;
  component: string;
  isDisabled: boolean;
}
export interface IInvoicePreview {
  html: string;
  url: string;
}

const GenericPageLoadErrorMessage = "Error loading page. Please try again, or contact your service provider.";
const GenericActionFailedErrorMessage = "Error performing action. Please try again, or contact your service provider.";
export { GenericPageLoadErrorMessage, GenericActionFailedErrorMessage };

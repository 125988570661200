import { ICmpAlert, eAlertType } from "@/components/CmpAlerts/cmpAlertLib";
import AccountService from "@/services/AccountService";
import { eActions, store } from "@/store";
import { EnvOptions } from "@/settings/envOptions";
import {
  eAppAccessType,
  IAppointmentCardData,
  IAppointmentPropertyData,
  ICustomerInfo,
  IPropertyData,
  IQuoteCardData,
} from "./shared-definitions";

export function toFormattedAddress(
  obj: IPropertyData | IAppointmentCardData | IQuoteCardData | ICustomerInfo | null | undefined
): string {
  if (!obj) return "";

  return (
    (obj.address1 ? obj.address1 : "") +
    ((obj as IAppointmentPropertyData).line1 ? (obj as IAppointmentPropertyData).line1 : "") +
    (obj.address2 ? ", " + obj.address2 + " " : "") +
    ((obj as IAppointmentPropertyData).line2 ? ", " + (obj as IAppointmentPropertyData).line2 : "") +
    (obj.city ? " " + obj.city : "") +
    (obj.state ? ", " + obj.state + " " : "") +
    (obj.zip ? obj.zip + " " : "")
  );
}

export function triggerErrorAlert(message: string) {
  const cmpAlert: ICmpAlert = {
    alertMessage: message,
    alertType: eAlertType.Error,
  };
  store.dispatch(eActions.setCmpAlert, cmpAlert);
}

export function triggerSuccessAlert(message: string) {
  const cmpAlert: ICmpAlert = {
    alertMessage: message,
    alertType: eAlertType.Success,
  };
  store.dispatch(eActions.setCmpAlert, cmpAlert);
}

export function triggerAlert(message: string, alertType: eAlertType) {
  const cmpAlert: ICmpAlert = {
    alertMessage: message,
    alertType: alertType,
  };
  store.dispatch(eActions.setCmpAlert, cmpAlert);
}

/**
 * given a a special idToken which includes registration info, try to register the current authenticated user
 * for the contained company/customer in the registration.
 *
 * Note: side effects - vuex store usage. This will manipulate tracked app logic level auth state and stored account information
 * Note: external calls to backed to create/retrieve
 * @param idToken the special idToken from an email link that contains registration info.
 * @param accountService account service we'll use to register with.
 */
export async function processRegistrationAsync(idToken: string, accountService: AccountService): Promise<string> {
  // TODO: refactor as not to return route names / magic strings.
  // Ths was ported from an area that had that explicit need/design. It can now be refactored.
  try {
    const t2 = idToken.split(".");
    const t3 = t2[1];
    const t4 = atob(t3);
    console.log(idToken);
    console.log(t2);
    console.log(t3);
    console.log(t4);

    const jwtClaims = JSON.parse(t4);

    const tc = store.state.idToken as string;
    const tc_2 = tc.split(".");
    const tc_3 = tc_2[1];
    const tc_4 = atob(tc_3);
    const authedUserJwtClaims = JSON.parse(tc_4);

    //Note: with redirect flow and the standlone auth/App.vue which forces a clean auth cache, we should
    //not see this. However, if we use processRegistrationAsync for popup flow in the future, this could still be important to check.
    if (jwtClaims.sub !== authedUserJwtClaims.sub) {
      console.error(
        `account miss-match: target b2cAccount: ${jwtClaims.sub} current b2cAccount: ${authedUserJwtClaims.sub}`
      );
      return "account-miss-match";
    }

    await store.dispatch(eActions.setAccessType, eAppAccessType.PUBLIC);
    if (jwtClaims.feCompanyId) {
      //TODO: review/refactor. we shouldn't need to do this now. Current b2c account should align.
      await store.dispatch(eActions.setIdToken, idToken);

      const response = await accountService.postAccountInfo({
        id: jwtClaims.sub!,
        feCompanyId: jwtClaims.feCompanyId!,
        feCustomerId: jwtClaims.feUserId!,
        email: jwtClaims.email!,
        idProvider: jwtClaims.idp!,
        companyAssociations: [{ feCompanyId: jwtClaims.feCompanyId!, feCustomerId: jwtClaims.feUserId! }],
      });

      await store.dispatch(eActions.setCurrentUser, response);
      try {
        //Note: for now this is essentially inert when we are using the redirect work flow.
        const companyInfo = await accountService.getCompanyInfo(jwtClaims.feCompanyId, jwtClaims.feUserId);
        await store.dispatch(eActions.setCurrentCompany, companyInfo);
        await store.dispatch(eActions.setAccessType, eAppAccessType.AUTHENTICATED);

        return "home";
      } catch (err) {
        console.error(err);
        //todo: this is assuming not authorized.
        //need structured errors type to flatten exception handling
        return "not-authorized";
      }
    }
  } catch (err) {
    console.error(err);
    //todo: this is assuming not found.
    //need structured errors type to flatten exception handling
    return "not-found";
  }

  return "home";
}

//ported from: https://jonlabelle.com/snippets/view/javascript/javascript-functions-to-parse-url-hash-and-query-string-parameters
/**
 * returns hash parameters as accessible params object.
 *
 * @param {string} hash string containing parameters.
 * @return {URLSearchParams} object of parameters
 */
export function getHashQueryStringParams(hash: string): URLSearchParams {
  const params = new URLSearchParams(hash.substring(1));
  return params;
}

export function CreateGuid(): string {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0;
    const v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export function CreateUltraShortGuid(): string {
  return "xxxx-xxxxx".replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0;
    const v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export function isStringNullOrEmpty(value: string) {
  return value == null || value === "" || value == undefined;
}

import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "align-start d-flex flex-row flex-wrap" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_alert = _resolveComponent("v-alert")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      width: "24",
      class: "mr-0 mt-0",
      src: 
        _ctx.alertType == _ctx.alertTypes.Success
          ? require('../../assets/check-circle-green.svg')
          : require('../../assets/check-circle-red.svg')
      
    }, null, 8, _hoisted_2),
    _createVNode(_component_v_alert, {
      text: _ctx.alertMessage,
      color: "surface",
      class: "ml-0 pa-0 pl-2"
    }, null, 8, ["text"])
  ]))
}
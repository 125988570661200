
import { defineComponent, ref } from "vue";
import { useDisplay, useTheme } from "vuetify";
import { eActions, useStore } from "../../store";
import "../../scss/main.scss";
import "../../scss/cmp-alerts.scss";

import BottomNav from "../../components/BottomNav.vue";
import AppHeader from "../../components/AppHeader.vue";
import NavDrawer from "../../components/NavDrawer.vue";

import { useMsalAuthentication } from "../../composition-api/useMsalAuthentication";
import { AuthenticationResult, AuthError, InteractionType } from "@azure/msal-browser";
import { loginRequest } from "../../settings/authConfig";
import AccountService from "../../services/AccountService";
import {
  eAppAccessType,
  ICompanyData,
  ICustomerData,
  IPropertyData,
  GenericPageLoadErrorMessage,
} from "@/lib/shared-definitions";

import CmpTopLevelAlertPresenter from "../../components/CmpAlerts/CmpTopLevelAlertPresenter.vue";
import { triggerErrorAlert } from "@/lib/utils";
import { IIdTokenClaims } from "@/lib/auth/IIdTokenClaims";
import { EnvOptions } from "@/settings/envOptions";
import { useMsal } from "@/composition-api/useMsal";
import { NavigationSections } from "@/lib/navigation-constants";

type UnsubscribeFunc = { (): void };

enum eDesignMode {
  MOBILE = 1,
  RESPONSIVE = 2,
}

//https://vuejs.org/guide/typescript/overview.html#definecomponent
export default defineComponent({
  name: "App",
  components: { NavDrawer, BottomNav, AppHeader, CmpTopLevelAlertPresenter },
  setup() {
    const { result, acquireToken, error, inProgress } = useMsalAuthentication(InteractionType.Redirect, loginRequest);
    const { instanceMsal } = useMsal();

    const accountService = ref(new AccountService());
    const { mobile, smAndUp, sm, mdAndUp } = useDisplay();
    const store = useStore();

    const theme = useTheme();

    const user = {} as ICustomerData | undefined;
    const company = {} as ICompanyData | undefined;
    const navSections = NavigationSections;

    const mode = eDesignMode.MOBILE;
    let startDrawer = mdAndUp.value;
    const drawer = ref(startDrawer);

    store.dispatch(eActions.setDisplayStylized, mdAndUp);

    //todo: remove this debug/testing tooling. hold until deployed dev env review.
    //hows for boogering of auth via browser console use case testing.
    (window as any)["boogerAuth"] = function () {
      //localStorage.clear();
      localStorage.setItem(`msal.${EnvOptions.B2cClientId}.active-account`, "boogers");
      store.dispatch(eActions.setIdToken, "boogers");
    } as any;

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    const unsubscribe: UnsubscribeFunc = () => {};

    return {
      mobile,
      theme,
      user,
      company,
      navSections,
      mode,
      drawer,
      smAndUp,
      sm,
      mdAndUp,
      store,
      unsubscribe,
      authResult: result,
      accountService,
      acquireToken,
      inProgress,
      error,
      instanceMsal,
    };
  },

  data: () => ({
    isLoading: true,
    timeoutId: 0,
  }),

  computed: {
    isMobileDesign() {
      return this.mode == eDesignMode.MOBILE;
    },
    isResponsiveDesign() {
      return this.mode == eDesignMode.RESPONSIVE;
    },
    showPublicMode() {
      return this.store.state.accessType === eAppAccessType.PUBLIC;
    },
    accessType() {
      return this.store.state.accessType;
    },
    currentProperty: {
      get() {
        return this.store.state.currentProperty;
      },
      set(value: IPropertyData) {
        this.store.dispatch(eActions.setCurrentProperty, value);
      },
    },
    currentCompany() {
      return this.store.state.currentCompany;
    },
    properties() {
      return this.store.state.properties;
    },
  },

  watch: {
    async drawer(nval: boolean) {
      await this.store.dispatch(eActions.setNavDrawerOpen, nval);
    },
    error(nval: AuthError) {
      console.error(nval.errorMessage);
      //todo: to start with, when an auth error occurs we are, blindly triggering re-auth.
      triggerErrorAlert(nval.message);
      setTimeout(() => {
        this.store.dispatch(eActions.setTriggerAuth, true);
      }, 5000);
    },

    //Makes App.vue responsible for monitoring changes in auth.
    //todo: mjb - need to revisit how we'll support public access.
    async authResult(nval: AuthenticationResult) {
      if (nval === null) {
        await this.store.dispatch(eActions.setIdToken, "");
        await this.store.dispatch(eActions.setAccessType, eAppAccessType.PUBLIC);
      } else {
        //set token into storage. this will be used for api calls.
        await this.store.dispatch(eActions.setIdToken, nval.idToken);
        try {
          //Note these can fail due to server side issues ranging from missmatched configuration on new deployements to
          //just expired token, to ... well other.
          //todo: these service calls can likely throw axios errors
          console.log("account service calls triggered");

          try {
            //load current cmpAccount
            let cmpAccount = await this.accountService.getAccountInfo(nval.uniqueId);

            //detect and update b2c account email changes for cmp account.
            const claims: IIdTokenClaims = nval.idTokenClaims as IIdTokenClaims;
            if (claims.email !== cmpAccount.email) {
              console.log(`email change detected ${cmpAccount.email} -> ${claims.email} updating`);
              cmpAccount = await this.accountService.putAccountEmail(cmpAccount.id, claims.email);
              //BUG: even though msal is picking up on the new email to some degree. If a password change is attempted
              //without 1st loging out, the password change flow will encounter an error "'A user with the specified credential could not be found' error message'"
              await this.instanceMsal.logoutRedirect();
            }

            await this.store.dispatch(eActions.setCurrentUser, cmpAccount);

            //todo: we may want another eAppAccessType that reprents finding account info, but failing
            // to acquire a current company.
            const cmpCompany = await this.accountService.getCompanyInfo(
              cmpAccount.feCompanyId,
              cmpAccount.feCustomerId
            );

            const propertiesList = await this.accountService.getPropertiesInfo(
              cmpAccount.feCompanyId,
              cmpAccount.feCustomerId
            );

            await this.store.dispatch(eActions.setCurrentCompany, cmpCompany);

            await this.store.dispatch(eActions.setProperties, propertiesList);

            if (propertiesList.length > 0) {
              await this.store.dispatch(eActions.setCurrentProperty, propertiesList[0]);
            }

            await this.store.dispatch(eActions.setAccessType, eAppAccessType.AUTHENTICATED);

            //The number of minutes returned by getTimezoneOffset() is positive if the local time zone
            //is behind UTC, and negative if the local time zone is ahead of UTC.
            //.NET's offset is the value that should be added to the UTC time to get to local time.
            //deviding timezoneOffset to -60 to get hours and change sign of the number to have correct value for the backend
            const timezoneOffset = new Date().getTimezoneOffset() / -60;

            await this.store.dispatch(eActions.setTimezoneOffset, timezoneOffset);
          } catch (err) {
            console.error(err);
            triggerErrorAlert(GenericPageLoadErrorMessage);

            await this.store.dispatch(eActions.setAccessType, eAppAccessType.PUBLIC);
            this.$router.push({ name: "not-authorized" });
          }
        } catch (err) {
          //todo: currently axios/cmpbackend.ts is setup with a response interceptor which will
          //trigger a re-auth on 401 errors. However, the errors should still progress to the account service calls.
          //If a full re-auth occurs, then these service calls will occur again.
          //However, in the event of partial auth, like with acquire token silently. I'm not confident these
          //service calls will occur.
          console.error(err);
          //todo: notify
        }
      }
    },
    mdAndUp(nval: boolean) {
      if (nval) {
        this.drawer = true;
        this.store.dispatch(eActions.setDisplayStylized, true);
      } else {
        this.drawer = false;
        this.store.dispatch(eActions.setDisplayStylized, false);
      }
    },
  },

  created(): void {
    //Listen for changes in state.
    this.unsubscribe = this.store.subscribe(async (mutation, state) => {
      if (mutation.type === eActions.setAccessType && state.accessType) {
        //if we gott'em use'em
        //todo: we can probably update these to computed props that use these stored state values directly.
        this.user = this.store.state.currentCompany?.customer;
        this.company = this.store.state.currentCompany?.company;

        //todo: how do we want to handle different access use cases.
        //authed: simple we show the UI we have already
        //not-authed: present them a login page
        //special access: future enhancement to support public accessible appointment request form, for this we'll likey want both a different access type an app entry point defined in vue.config.js
        //Note: timeout only used so we can see effect
        setTimeout(() => {
          this.isLoading = false;
        }, 0);
      }

      //if the mutation is an auth trigger due to some issue like an api 401.
      //then try to correct.
      //Note: we use this subscribe approach to ensure we can review all calls to set to true.
      if (mutation.type === eActions.setTriggerAuth && state.triggerAuth) {
        //for safety. limit recurrence.
        if (this.timeoutId !== 0) return;

        //hack to reduce call frequency to 1 per second.
        //I'm not yet sure how frequent this could be called.

        //There was likely an api auth issue which triggered this flow.
        //We'll logout app level use data like current company and attempt to require.
        //Currently we'll rely on the user to click around to re-try
        await this.store.dispatch(eActions.setTriggerAuth, false);
        this.timeoutId = setTimeout(async () => {
          console.log("triggerAuth");
          this.timeoutId = 0;
          await this.store.dispatch(eActions.logout);

          //this should try to
          //- reacquire silently
          //- trigger a redirect login flow if that fails.
          this.acquireToken();
        }, 1000);
      }
    });
  },

  beforeUnmount() {
    this.unsubscribe();
  },

  methods: {
    onToggleDrawer() {
      this.drawer = !this.drawer;
    },
  },
});

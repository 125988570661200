import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-46014af4"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_bottom_navigation = _resolveComponent("v-bottom-navigation")!

  return (_openBlock(), _createBlock(_component_v_bottom_navigation, {
    class: "bottom-navigation-style",
    grow: ""
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.links, (link) => {
        return (_openBlock(), _createBlock(_component_v_btn, {
          key: link.icon + link.name,
          ripple: false,
          value: "recent",
          onClick: ($event: any) => {
        _ctx.$router.push(link.path);
        _ctx.onClick(_ctx.links);
        link.isActive = true;
      }
        }, {
          default: _withCtx(() => [
            _createElementVNode("img", {
              src: link.isActive ? link.mobileIconPathActive : link.mobileIconPath
            }, null, 8, _hoisted_1),
            _createElementVNode("span", {
              class: _normalizeClass({ 'text-style': link.isActive })
            }, _toDisplayString(link.name), 3)
          ]),
          _: 2
        }, 1032, ["onClick"]))
      }), 128))
    ]),
    _: 1
  }))
}
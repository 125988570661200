import { QuoteModel, WorkOrderModel } from "fieldedge-typescript-models-core";
import { EnvelopeStatus } from "@/FE_Enums/EnvelopeStatus";
import { DateTime } from "luxon";
import { EmptyGuid } from "../shared-constants";
import { QuoteOptionModel } from "fieldedge-typescript-models-core/models/QuoteModel";

export enum eWorkflowType {
  None,
  InvoiceAndQuote,
  QuoteOnly,
  InvoiceOnly,
}

//start: new models

//interfaces
export interface IWorkOrderInfoViewModel {
  WorkOrder: WorkOrderModel;
  FieldEdgePayments: Array<FieldEdgePayment>;
  WorkflowType: eWorkflowType;
  IsElectronicSignature: boolean;
}

export interface IConsumerPortalLinkInfoViewModel {
  workOrderId: string;
  companyId: string;
  quoteAccepted: boolean;
  quoteSkipped: boolean;
  quoteDeclined: boolean;
  payLater: null;
  paymentAmount: number;
  isElectronicSignature: boolean;
  quoteToAccept: QuoteModel | null;
}
export interface IPaymentInfoModel {
  companyId: string;
  workOrderId: string;
  workOrderName: string;
  customerName: string;
  balanceDue: number;
  amount: number;
  amountWithCash: number;
  chargeKey: string;
  zip: string;
  isPaid: boolean;
  paymentIndex: string;
  dispatchTaskId: string;
  cashDiscountApplied: number;
  cashDiscountAppliedWithCash: number;
}
export interface IBalanceAmounts {
  TotalAmount: number;
  MinimumAmount: number;
}
// end of interfaces

export class BalanceAmounts implements IBalanceAmounts {
  TotalAmount: number;
  MinimumAmount: number;
  constructor() {
    this.TotalAmount = 0;
    this.MinimumAmount = 0;
  }
}

export class WorkOrderInfoViewModel implements IWorkOrderInfoViewModel {
  WorkOrder: WorkOrderModel;
  FieldEdgePayments: FieldEdgePayment[];
  WorkflowType: eWorkflowType;
  IsElectronicSignature: boolean;
  constructor() {
    this.WorkOrder = new WorkOrderModel();
    this.FieldEdgePayments = [];
    this.WorkflowType = eWorkflowType.None;
    this.IsElectronicSignature = false;
  }
}

export class ConsumerPortalLinkInfoViewModel implements IConsumerPortalLinkInfoViewModel {
  workOrderId: string;
  companyId: string;
  payLater: null;
  paymentAmount: number;
  quoteAccepted: boolean;
  quoteSkipped: boolean;
  quoteDeclined: boolean;
  isElectronicSignature: boolean;
  quoteToAccept: QuoteModel | null;
  constructor(workOrderId: string, companyId: string) {
    this.workOrderId = workOrderId;
    this.companyId = companyId;
    this.quoteAccepted = false;
    this.quoteSkipped = false;
    this.quoteDeclined = false;
    this.payLater = null;
    this.paymentAmount = 0;
    this.isElectronicSignature = false;
    this.quoteToAccept = null;
  }
}

export class FieldEdgePayment {
  public PaymentID: string;
  public PaymentMethodName: string;
  public Amount: string;
  public DateAdded: Date;
  public OrderIndex: number;
  public IsPendingPayment: boolean;

  constructor() {
    this.PaymentID = EmptyGuid;
    this.PaymentMethodName = "";
    this.Amount = "";
    this.DateAdded = new Date(0);
    this.OrderIndex = 0;
    this.IsPendingPayment = false;
  }
}

export class PaymentInfoRequest {
  companyId: string;
  paymentAmount: number;
  depositPercent: number;
  workOrder: WorkOrderModel;
  isPayLater: boolean;

  constructor(
    companyId = "",
    paymentAmount = 0,
    depositPercent = 0,
    workOrder: WorkOrderModel = new WorkOrderModel(),
    isPayLater: boolean
  ) {
    this.companyId = companyId;
    this.paymentAmount = paymentAmount;
    this.depositPercent = depositPercent;
    this.workOrder = workOrder;
    this.isPayLater = isPayLater;
  }
}
//end new models

export class ElectronicSignatureViewModel {
  redirectUrl: string;
  isLocked: boolean;
  lockedUntil: Date;
  lockedByUserName: string;
  envelopeStatus: EnvelopeStatus;

  constructor() {
    this.redirectUrl = "";
    this.isLocked = false;
    this.lockedUntil = new Date(0);
    this.lockedByUserName = "";
    this.envelopeStatus = EnvelopeStatus.None;
  }
}
export class PaymentInfoModel implements IPaymentInfoModel {
  companyId: string;
  companyName: string;
  workOrderId: string;
  workOrderName: string;
  customerName: string;
  balanceDue: number;
  amount: number;
  amountWithCash: number;
  chargeKey: string;
  zip: string;
  isPaid: boolean;
  isQBDPayment: boolean;
  paymentIndex: string;
  dispatchTaskId: string;
  cashDiscountApplied: number;
  cashDiscountAppliedWithCash: number;
  constructor() {
    this.workOrderId = EmptyGuid;
    this.workOrderName = "";
    this.companyName = "";
    this.companyId = "";
    this.customerName = "";
    this.amountWithCash = 0;
    this.balanceDue = 0;
    this.amount = 0;
    this.chargeKey = "";
    this.isPaid = false;
    this.isQBDPayment = false;
    this.paymentIndex = "";
    this.zip = "";
    this.dispatchTaskId = "";
    this.cashDiscountApplied = 0;
    this.cashDiscountAppliedWithCash = 0;
  }
}

export class PaymentInfoViewModel {
  // Inherited properties

  // DISPATCHID
  dispatchId: string;

  companyID: string;
  // AGREEMENTID
  agreementId: null;
  // BALANCEDUE
  balanceDue: number;
  // COMPANYNAME
  companyName: string;
  // CUSTOMERNAME
  customerName: string;
  // ISMISSED
  isMissed: boolean;
  // RECURRANCEID
  recurranceId: null;
  // ENTITYID
  entityId: string;
  // AGREEMENTNAME
  agreementName: null;
  // DISPATCHTASKID
  dispatchTaskId: string;
  // PAYMENTINDEX
  paymentIndex: string;
  // DISPATCHNAME
  dispatchName: string;
  // SAVEFORFUTURETRANSACTION
  saveForFutureTransaction: boolean;
  // CHARGEKEY
  chargeKey: string;
  // ZIP
  zip: string;
  // ISPAID
  isPaid: boolean;
  // ISAGREEMENTACTIVE
  isAgreementActive: boolean;
  // CASHDISCOUNTAPPLIED
  cashDiscountApplied: number;
  // CASHDISCOUNTAPPLIEDWITHCASH
  cashDiscountAppliedWithCash: number;
  // AMOUNTWITHCASH
  amountWithCash: number;

  moduleName: string;

  constructor(newValue: PaymentInfoModel, entityId: string) {
    this.agreementId = null;
    this.dispatchId = newValue.workOrderId;
    this.companyID = newValue.companyId;
    this.companyName = newValue.companyName;
    this.balanceDue = newValue.balanceDue;
    this.customerName = newValue.customerName;
    this.isMissed = false;
    this.recurranceId = null;
    this.entityId = entityId;
    this.agreementName = null;
    this.dispatchTaskId = newValue.dispatchTaskId;
    this.paymentIndex = newValue.paymentIndex;
    this.dispatchName = newValue.workOrderName;
    this.saveForFutureTransaction = false;
    this.chargeKey = newValue.chargeKey;
    this.zip = newValue.zip;
    this.isPaid = newValue.isPaid;
    this.isAgreementActive = false;
    this.cashDiscountApplied = newValue.cashDiscountApplied;
    this.cashDiscountAppliedWithCash = newValue.cashDiscountAppliedWithCash;
    this.amountWithCash = newValue.amountWithCash;
    this.moduleName = "paymentInfo";
  }
}

export class ActivityNoteViewModel {
  id: number;
  dateTime: Date;
  text: string;
  locale: string;
  constructor(_locale: string) {
    this.id = 0;
    this.dateTime = new Date();
    this.text = "";
    this.locale = _locale;
  }

  getPaymentTextPrefix(paymentMethodText: string) {
    const vowels = "aeiouAEIOU";
    ``;
    return vowels.indexOf(paymentMethodText[0]) !== -1 ? "An" : "A";
  }

  formatDate(date: Date) {
    return DateTime.fromISO(date.toString(), { zone: "utc" })
      .setLocale(this.locale)
      .toLocaleString(DateTime.DATE_SHORT);
  }

  getNoteFromFieldEdgePayment(payment: FieldEdgePayment): ActivityNoteViewModel {
    return {
      dateTime: payment.DateAdded,
      text:
        this.getPaymentTextPrefix(payment.PaymentMethodName) +
        " " +
        payment.PaymentMethodName +
        " payment of $" +
        payment.Amount +
        " was paid on " +
        this.formatDate(payment.DateAdded) +
        ".",
    } as ActivityNoteViewModel;
  }
  getNoteFromPartialPayment(payment: FieldEdgePayment): ActivityNoteViewModel {
    return {
      dateTime: payment.DateAdded,
      text:
        this.getPaymentTextPrefix(payment.PaymentMethodName) +
        " " +
        payment.PaymentMethodName +
        " payment of $" +
        payment.Amount +
        " was paid.",
    } as ActivityNoteViewModel;
  }

  getNoteFromSkippedQuote(quote: QuoteModel): ActivityNoteViewModel {
    return {
      text: "Quote " + quote.TransactionNumber + " was skipped.",
      //  text: "Quote " + quote.number + " was skipped on " + this.formatDate(skippedQuoteTime) + ".",
    } as ActivityNoteViewModel;
  }

  //FE-17593 docusign rejected quote
  getNoteFromDeclinedQuote(quote: QuoteModel): ActivityNoteViewModel {
    return {
      text: "Quote " + quote.TransactionNumber + " was voided.",
      //  text: "Quote " + quote.number + " was voided on " + this.formatDate(declinedQuoteTime),
    } as ActivityNoteViewModel;
  }

  getNoteFromAcceptedQuote(quote: QuoteModel): ActivityNoteViewModel {
    let noteText = "Quote " + quote.TransactionNumber;

    if (quote.Options != null && quote.Options.length > 0) {
      quote.Options.forEach((quoteOption: QuoteOptionModel) => {
        if (quoteOption.Accepted && quoteOption.OptionID !== EmptyGuid) {
          noteText += ", " + quoteOption.Description;
        }
      });
    }
    noteText += " was accepted.";
    //noteText += " was accepted on " + this.formatDate(acceptedQuoteTime) + ".";

    return {
      text: noteText,
    } as ActivityNoteViewModel;
  }
}

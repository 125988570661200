import { resolveComponent as _resolveComponent, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, mergeProps as _mergeProps, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-13520b1c"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_app_bar_nav_icon = _resolveComponent("v-app-bar-nav-icon")!
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_responsive = _resolveComponent("v-responsive")!
  const _component_v_app_bar_title = _resolveComponent("v-app-bar-title")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_PropertyMenu = _resolveComponent("PropertyMenu")!
  const _component_MyAccountMenu = _resolveComponent("MyAccountMenu")!
  const _component_v_app_bar = _resolveComponent("v-app-bar")!

  return (_openBlock(), _createBlock(_component_v_app_bar, {
    height: 50,
    color: "primary",
    density: "compact"
  }, {
    default: _withCtx(() => [
      (_ctx.showDrawerButton)
        ? (_openBlock(), _createBlock(_component_v_app_bar_nav_icon, {
            key: 0,
            color: "black",
            onClick: _withModifiers(_ctx.onToggleDrawer, ["stop"])
          }, null, 8, ["onClick"]))
        : _createCommentVNode("", true),
      (_ctx.showDetails)
        ? (_openBlock(), _createBlock(_component_v_responsive, {
            key: 1,
            "max-width": "50",
            class: "ml-4 flex-logo"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_img, {
                width: 24,
                height: 24,
                class: "rounded-logo",
                src: _ctx.companyLogo
              }, null, 8, ["src"])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_ctx.showDetails)
        ? (_openBlock(), _createBlock(_component_v_app_bar_title, {
            key: 2,
            class: "mobile-header-title"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.currentCompany?.company?.name), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_PropertyMenu, {
        modelValue: _ctx.propertySelected,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.propertySelected) = $event)),
        properties: _ctx.properties
      }, {
        activator: _withCtx(({ props }) => [
          (_ctx.showDetails)
            ? (_openBlock(), _createBlock(_component_v_btn, _mergeProps({ key: 0 }, props, {
                ripple: false,
                class: "ma-2 properties-logo",
                variant: "text",
                color: "black"
              }), {
                default: _withCtx(() => [
                  _createElementVNode("img", {
                    src: require('../assets/NavigationMobileIcons/properties-mobile.svg')
                  }, null, 8, _hoisted_1)
                ]),
                _: 2
              }, 1040))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["modelValue", "properties"]),
      _createVNode(_component_MyAccountMenu, null, {
        activator: _withCtx(({ props }) => [
          (_ctx.showDetails)
            ? (_openBlock(), _createBlock(_component_v_btn, _mergeProps({ key: 0 }, props, {
                ripple: false,
                class: "ma-2 profile-logo",
                variant: "text",
                color: "black"
              }), {
                default: _withCtx(() => [
                  _createElementVNode("img", {
                    src: require('../assets/NavigationMobileIcons/profile-mobile.svg')
                  }, null, 8, _hoisted_2)
                ]),
                _: 2
              }, 1040))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
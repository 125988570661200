
import {
  eAppAccessType,
  eIconPosition,
  eIconPositionMixin,
  ICompanyCustomerPair,
  INavLink,
  INavSection,
  IPropertyData,
} from "@/lib/shared-definitions";
import { defineComponent, PropType, ref } from "vue";
import MyAccountMenu from "@/components/MyAccountMenu.vue";
import PropertyMenu from "@/components/PropertyMenu.vue";
import { CreateUltraShortGuid } from "@/lib/utils";
import * as Utils from "@/lib/utils";
import AccountService from "@/services/AccountService";
import { timers } from "jquery";
export default defineComponent({
  name: "NavDrawer",
  components: { MyAccountMenu, PropertyMenu },
  mixins: [eIconPositionMixin],
  props: {
    ///background color
    bgColor: {
      type: String,
      default: "#FFFFFF",
    },
    modelValue: {
      type: Boolean,
      default: true,
    },
    navSections: {
      type: Array as PropType<Array<INavSection>>,
      default: () => {
        return [];
      },
    },
    accessType: {
      type: Number as PropType<eAppAccessType>,
      default: eAppAccessType.NOT_SET,
    },
    properties: {
      type: Array as PropType<Array<IPropertyData>>,
      default: () => {
        return [];
      },
    },
    currentProperty: {
      type: Object as PropType<IPropertyData>,
      require: true,
    },
    currentCompany: {
      type: Object as PropType<ICompanyCustomerPair | null>,
      default: null,
    },
  },
  setup(props) {
    const openDrawer = ref(props.modelValue);

    return { openDrawer };
  },
  emits: ["update:modelValue", "update:currentProperty"],
  data: () => ({
    showAllProperties: false,
    SidePanelItemList: [],
  }),
  computed: {
    companyNameUpdates() {
      return this.currentCompany?.company.name;
    },
    companyLogoPathUpdates() {
      return this.currentCompany?.company.logoUrl as string;
    },
    companyLogo() {
      return this.companyLogoPathUpdates ? this.companyLogoPathUpdates + "?img=" + CreateUltraShortGuid() : "";
    },
    propertySelected: {
      get() {
        console.log("nd get: " + this.currentProperty?.entityId);
        return this.currentProperty;
      },
      set(value: IPropertyData) {
        console.log("nd set: " + value.entityId);

        this.$emit("update:currentProperty", value);
      },
    },
  },
  watch: {
    modelValue(nval: boolean) {
      this.openDrawer = nval;
    },
    openDrawer(nval: boolean) {
      //note: avoiding a cyclic update loop.
      if (nval === this.modelValue) return;

      this.$emit("update:modelValue", nval);
    },
  },
  methods: {
    isDisabledLink(link: INavLink) {
      const allowedType = link.accessTypes.find((t) => {
        return t === this.accessType;
      });
      return !allowedType;
    },
    getIconPosition() {
      return eIconPosition.LEFT;
    },

    propertyName(property: IPropertyData): string {
      return Utils.toFormattedAddress(property) + (property.isJob ? " - Job" : "");
    },
    isAllSelected(prop: IPropertyData) {
      return AccountService.isAllProperty(prop);
    },
  },
});

import { createApp } from "vue";
import App from "./App.vue";
import router from "../../router";
import { store, key } from "../../store";
import vuetify from "../../plugins/vuetify";
import { loadFonts } from "../../plugins/webfontloader";
import { msalPlugin } from "../../plugins/msalPlugin";
import { msalInstance } from "../../settings/authConfig";
import { AuthenticationResult, EventMessage, EventType } from "@azure/msal-browser";
import { CustomNavigationClient } from "@/router/NavigationClient";

loadFonts();

//todo: mjb - pending review. will we want this later?
// The next 2 lines are optional. This is how you configure MSAL to take advantage of the router's navigate functions when MSAL redirects between pages in your app
//todo: using this to tap into routing with breakpoints.
const navigationClient = new CustomNavigationClient(router);
msalInstance.setNavigationClient(navigationClient);

//in the event that auth data is stored and available for refresh
//this will attempted to grab and set the active account.
const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
}

//used to set the active account on new login.
msalInstance.addEventCallback((event: EventMessage) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload as AuthenticationResult;
    const account = payload.account;
    msalInstance.setActiveAccount(account);
  }
});

const app = createApp(App);
app.use(router).use(store, key).use(vuetify).use(msalPlugin, msalInstance);
router.isReady().then(async () => {
  // Note: from ms vue3 msal sample. Note confident this help in the end. keeping beacause is was the last mode I tested.
  // Waiting for the router to be ready prevents race conditions when returning from a loginRedirect or acquireTokenRedirect
  app.mount("#app");
});
